import { type ReactElement } from 'react'
import { Nav, Offcanvas } from 'react-bootstrap'
import SupportMenu from './SupportMenu'
import AccountMenu from './AccountMenu'
import { BsList, BsXLg } from 'react-icons/bs'
import SidebarNavigation from './SidebarNavigation'
import useIsMobile from '../hooks/useIsMobile'
import MaIconButton from './MaIconButton'
import styles from '../assets/scss/UserToolbar.module.scss'
import BasketPreview from '../../basket/components/BasketPreview'

export interface UserToolbarProps {
  offcanvasNavVisible: boolean
  onOpenOffcanvasNav: () => void
  onCloseOffcanvasNav: () => void
}

function UserToolbar ({
  offcanvasNavVisible,
  onOpenOffcanvasNav,
  onCloseOffcanvasNav
}: UserToolbarProps): ReactElement {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <>
        <Nav className={styles['user-toolbar']}>
          {offcanvasNavVisible && (
            <MaIconButton onClick={onCloseOffcanvasNav} aria-label="Close menu">
              <BsXLg size={24} />
            </MaIconButton>
          )}
          {!offcanvasNavVisible && (
            <MaIconButton onClick={onOpenOffcanvasNav} aria-label="Open menu">
              <BsList size={24} />
            </MaIconButton>
          )}
        </Nav>

        <Offcanvas
          className={styles['offcanvas-menu']}
          show={offcanvasNavVisible}
          onHide={onCloseOffcanvasNav}
          placement="end"
          responsive="md"
          backdrop={false}
          data-testid="offcanvas-menu"
        >
          <Offcanvas.Body className="p-0">
            <SidebarNavigation />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    )
  }

  return (
    <Nav className={styles['user-toolbar']}>
      <Nav.Item>
        <BasketPreview />
      </Nav.Item>
      <Nav.Item>
        <SupportMenu />
      </Nav.Item>
      <Nav.Item>
        <AccountMenu />
      </Nav.Item>
    </Nav>
  )
}

export default UserToolbar
