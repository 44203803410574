import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnv } from '../../common/hooks/useEnv'
import styles from '../assets/scss/SearchSummary.module.scss'

interface ProductSearchSummaryProps {
  searchTerm: string
  totalElements?: number
  alternativesFor?: string
}

function ProductSearchSummary ({
  searchTerm,
  totalElements,
  alternativesFor
}: ProductSearchSummaryProps): ReactElement | null {
  const { t } = useTranslation()
  const env = useEnv()

  if (totalElements === undefined) {
    return null
  }

  if (totalElements === 0 && searchTerm) {
    return (
      <div className={styles.summary}>
        <h6>{t('product.search.summary.no_results', { term: searchTerm })}</h6>
        <p>{t('product.search.summary.we_recommend')}</p>
        <ul>
          <li>{t('product.search.summary.check_spelling')}</li>
          <li>{t('product.search.summary.keep_simple')}</li>
        </ul>
      </div>
    )
  }

  if (totalElements >= (env.REACT_APP_SEARCH_MAX_DOCS ?? 10000) && searchTerm) {
    return (
      <div className={styles.summary}>
        <p>
          {t('product.search.summary.results', {
            count: totalElements,
            term: searchTerm
          })}
        </p>
        <p>{t('product.search.summary.maxdocs')}</p>
      </div>
    )
  }

  return (
    <div className={styles.summary}>
      <p>
        {!alternativesFor &&
          t('product.search.summary.results', {
            count: totalElements,
            term: searchTerm
          })}
        {alternativesFor &&
          t('product.search.summary.alternative_products_results', {
            count: totalElements,
            productName: alternativesFor
          })}
      </p>
    </div>
  )
}

export default ProductSearchSummary
