import { useEnv } from '../hooks/useEnv'
import LoadingScreen from './LoadingScreen'
import { AuthProvider } from 'react-oidc-context'
import { type ReactElement, type ReactNode } from 'react'

const AUTH_CALLBACK_PARAM_KEYS = [
  'code',
  'scope',
  'state',
  'session_state',
  'iss'
]

function Authentication ({ children }: { children: ReactNode }): ReactElement {
  const env: Record<string, string> = useEnv()

  if (Object.keys(env).length === 0) {
    return <LoadingScreen />
  }

  const oidcConfig = {
    authority: env.REACT_APP_OIDC_AUTHORITY ?? '',
    client_id: env.REACT_APP_OIDC_CLIENT_ID ?? '',
    redirect_uri:
      window.location.href.replace(/#.*$/, '') ??
      env.REACT_APP_OIDC_REDIRECT_URL ??
      '',
    loadUserInfo: true,
    onSigninCallback: (): void => {
      const searchParams = new URLSearchParams(window.location.search)

      AUTH_CALLBACK_PARAM_KEYS.forEach(authParam => {
        searchParams.delete(authParam)
      })

      if (searchParams.size > 0) {
        window.location.search = searchParams.toString()
      } else {
        window.location.href = window.location.pathname
      }
    },
    onSignoutRedirect: (): void => {
      window.location.pathname = ''
    }
  }

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>
}

export default Authentication
