import { type Product as SearchProduct } from '@amici/myamici-search-client'
import { type HTMLAttributes, type ReactElement, useRef } from 'react'
import ReactGA from 'react-ga4'
import { Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { type Product, ProductStatusEnum } from '@amici/myamici-api-client'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import classNames from 'classnames'
import MaCard from '../../common/components/MaCard'
import FallbackImage from '../../common/components/FallbackImage'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import FavouriteButton from '../../favourites/components/FavouriteButton'
import useProductPrice from '../hooks/useProductPrice'
import usePurchaseHistory from '../hooks/usePurchaseHistory'
import ProductPrice from './ProductPrice'
import ProductPricePerUnit from './ProductPricePerUnit'
import ProductStock from './ProductStock'
import SupplierStatusIndicator from './SupplierStatusIndicator'
import styles from '../assets/scss/ProductCard.module.scss'

const MIN_WIDTH_PX = 600

// TODO: (in another PR) Merge product params to generic union type rather than pass two product variants.
interface ProductCardProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'full' | 'alternative'
  product?: SearchProduct
  productSummary?: Product
  currentPage?: number
}

function ProductCard ({
  variant = 'full',
  product,
  productSummary,
  currentPage
}: ProductCardProps): ReactElement {
  const { t } = useTranslation()
  const cardBodyRef = useRef<HTMLDivElement>(null)
  const withinMinWidth = useMinWidthObserver(cardBodyRef, MIN_WIDTH_PX)

  const isAltCard = variant === 'alternative'
  const isFullCard = !isAltCard
  const stackView = isFullCard && !withinMinWidth

  const showChemicalInfo =
    isFullCard && !!(product?.casNumber ?? product?.purity ?? product?.smiles)

  const casNumber = product?.casNumber ?? '-'
  const smiles = product?.smiles ? product?.smiles : '-'
  const purity: number = product?.purity ? product?.purity / 100 : 0

  const noOfUnits = product?.noOfUnits ?? productSummary?.no_of_units ?? 0
  const packSize = product?.packSize ?? productSummary?.pack_size ?? 0
  const packSizeValue =
    (noOfUnits > 1 ? `${noOfUnits} x ` : '') + packSize.toString()
  const packSizeUnit: string =
    product?.packSizeUnit ?? productSummary?.pack_size_unit ?? ''

  const productPriceData = useProductPrice(
    product?.id.toString() ?? productSummary?.id ?? '',
    product?.currencyCode ?? null,
    product?.listPrice ?? null,
    product?.price ?? null
  )

  const previousPurchases = usePurchaseHistory(
    product?.id.toString() ?? productSummary?.id
  )?.data?.content
  const purchasedBefore = previousPurchases
    ? previousPurchases.length > 0
    : false

  const cannotBuy =
    !!product?.isSelfServe ||
    product?.productStatus === ProductStatusEnum.INACTIVE ||
    productPriceData.bestPrice == null ||
    productPriceData.bestPrice <= 0

  const productDetailsButtonMessage = cannotBuy
    ? 'product.card.view'
    : purchasedBefore
      ? 'product.card.view_and_buy_again'
      : 'product.card.view_and_buy'

  const handleDetailsPageLinkClick = (): void => {
    ReactGA.event('action_item_list', {
      item_list_id: isAltCard ? 'alternative_products' : 'search_results',
      item_id: product?.id ?? productSummary?.id,
      action: 'view_product_details',
      ...(currentPage && { page: currentPage })
    })
  }

  return (
    <MaCard
      className={classNames(styles['product-card'], {
        'alt-card': isAltCard,
        'stack-view': stackView
      })}
    >
      <MaCard.Header className={styles.title}>
        <Link
          to={`/purchasing/products/${product?.id ?? productSummary?.id ?? ''}`}
          onClick={handleDetailsPageLinkClick}
        >
          {product?.description ?? productSummary?.description}
        </Link>
        {!cannotBuy && (
          <FavouriteButton
            productId={product?.id.toString() ?? productSummary?.id ?? ''}
          />
        )}
      </MaCard.Header>

      <MaCard.Body>
        <div className={styles.content} ref={cardBodyRef}>
          <div
            className={classNames(
              styles['left-col'],
              stackView ? 'flex-column' : 'flex-row'
            )}
          >
            <Table className={styles.table}>
              <tbody>
                <tr>
                  <th>{t('product.card.brand')}</th>
                  <td>{product?.brand ?? productSummary?.brand}</td>
                </tr>
                <tr>
                  <th>{t('product.card.manufacturer_part_no')}</th>
                  <td>
                    {product?.manufacturePartNo ??
                    productSummary?.manufacturer_part_number
                      ? product?.manufacturePartNo ??
                        productSummary?.manufacturer_part_number
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <th>{t('product.card.pack_size')}</th>
                  <td>
                    {t('product.card.pack_size_value', {
                      packSizeValue,
                      packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                        count: +packSize
                      })
                    })}
                  </td>
                </tr>
              </tbody>
            </Table>

            {showChemicalInfo && (
              <Table className={styles.table}>
                <tbody>
                  <tr>
                    <th>{t('product.card.cas_number')}</th>
                    <td>{casNumber}</td>
                  </tr>
                  <tr>
                    <th>{t('product.card.smiles')}</th>
                    <td title={smiles}>{smiles}</td>
                  </tr>
                  <tr>
                    <th>{t('product.card.purity')}</th>
                    <td>
                      {purity > 0
                        ? t('product.card.purity_value', { purity })
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>

          <div className={styles['right-col']}>
            <div className={styles['img-wrapper']}>
              {(product?.supplierImageUrlSmall ??
                productSummary?.product_image_url) && (
                <FallbackImage
                  className={styles.img}
                  src={
                    product?.supplierImageUrlSmall ??
                    productSummary?.product_image_url ??
                    ''
                  }
                  alt={`Image for product '${
                    product?.description ?? productSummary?.description ?? ''
                  }'`}
                  loading="lazy"
                />
              )}
              {!(
                product?.supplierImageUrlSmall ??
                productSummary?.product_image_url
              ) &&
                (product?.smiles ?? productSummary?.smiles) && (
                  <div className={styles['chemical-structure-wrapper']}>
                    <SmilesSvgRenderer
                      width={88}
                      height={88}
                      smiles={product?.smiles ?? productSummary?.smiles ?? ''}
                      ErrorComponent={FallbackImage}
                    />
                  </div>
              )}
            </div>
          </div>
        </div>
      </MaCard.Body>

      <MaCard.Footer>
        <div
          className={classNames(styles['footer-content'], styles.content, {
            'alt-card': isAltCard
          })}
        >
          <div className={styles['upper-footer']}>
            <div
              className={classNames(
                styles['left-col'],
                stackView ? 'flex-column' : 'flex-row'
              )}
            >
              {isFullCard && (
                <Table className={styles.table}>
                  <tbody>
                    <tr>
                      <td className={styles.subtle} colSpan={2}>
                        <div className={styles.supplier}>
                          {product?.supplier}
                          <SupplierStatusIndicator
                            productId={product?.id.toString() ?? '0'}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('product.card.part_no')}</th>
                      <td>{product?.supplierPartNo}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
              {isAltCard && (
                <div
                  className={classNames(styles.supplier)}
                  title={
                    product?.supplier ?? productSummary?.supplier?.name ?? ''
                  }
                >
                  {product?.supplier ?? productSummary?.supplier?.name}
                </div>
              )}
              {!isFullCard &&
                !(product?.isSelfServe ?? productSummary?.is_self_serve) && (
                  <ProductPricePerUnit
                    productId={
                      product?.id.toString() ?? productSummary?.id ?? ''
                    }
                    currencyCode={product?.currencyCode}
                    listPrice={product?.listPrice}
                    price={product?.price}
                    noOfUnits={noOfUnits}
                    packSize={packSize}
                    unit={packSizeUnit}
                    isSelfServe={
                      !!(product?.isSelfServe ?? productSummary?.is_self_serve)
                    }
                  />
              )}
            </div>
            <div className={styles['right-col']}>
              <ProductPrice
                variant={'minimal'}
                productId={product?.id.toString() ?? productSummary?.id ?? ''}
                currency={product?.currencyCode ?? null}
                listPrice={product?.listPrice ?? null}
                bestPrice={product?.price ?? null}
                isSelfServe={
                  !!(product?.isSelfServe ?? productSummary?.is_self_serve)
                }
              />
              {isFullCard && (
                <ProductPricePerUnit
                  productId={product?.id.toString() ?? productSummary?.id ?? ''}
                  currencyCode={product?.currencyCode}
                  listPrice={product?.listPrice}
                  price={product?.price}
                  noOfUnits={noOfUnits}
                  packSize={packSize}
                  unit={packSizeUnit}
                  isSelfServe={
                    !!(product?.isSelfServe ?? productSummary?.is_self_serve)
                  }
                />
              )}
            </div>
          </div>
          {isFullCard && (
            <div className={styles['lower-footer']}>
              <ProductStock
                productId={product?.id?.toString() ?? ''}
                isSelfServe={!!product?.isSelfServe}
                status={product?.productStatus ?? ProductStatusEnum.INACTIVE}
              />
              <Link
                to={`/purchasing/products/${product?.id ?? ''}`}
                onClick={handleDetailsPageLinkClick}
              >
                <Button
                  className={classNames(
                    'rounded',
                    styles['view-details-button']
                  )}
                  variant="primary"
                >
                  {t(productDetailsButtonMessage)}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </MaCard.Footer>
    </MaCard>
  )
}

export default ProductCard
