import { type ReactElement } from 'react'
import { type LineItemResource } from '@amici/myamici-api-client'
import {
  type Column,
  type ColumnFilter,
  type ColumnSort,
  type Header
} from '@tanstack/react-table'
import { SortDirection } from '../../common/types/sort-direction'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'

function SortIcon ({
  column
}: {
  column: Column<LineItemResource, any>
}): ReactElement | null {
  const sortingOrder = column.getIsSorted()

  return column.getCanSort() && sortingOrder
    ? {
        [SortDirection.ASC]: <BsArrowUp />,
        [SortDirection.DESC]: <BsArrowDown />
      }[sortingOrder]
    : null
}

interface OrderItemsTableHeadProps {
  headers: Array<Header<LineItemResource, any>>
  onSortingChange: (sort: ColumnSort | null) => void
  onFilterChange?: (filter: ColumnFilter) => void
}

function OrderItemsTableHead ({
  headers,
  onSortingChange
}: OrderItemsTableHeadProps): ReactElement {
  const handleSortingChange = (id: string, value: string | false): void => {
    onSortingChange({ id, desc: value === SortDirection.ASC })
  }

  return (
    <thead>
      <tr>
        {headers.map((header: Header<LineItemResource, any>) => (
          <th key={header.id} className={header.id}>
            <h6
              className="heading"
              {...{
                ...(header.column.getCanSort() && {
                  role: 'button',
                  onClick: () => {
                    handleSortingChange(header.id, header.column.getIsSorted())
                  }
                })
              }}
            >
              {header.column.columnDef.header as string}{' '}
              <SortIcon column={header.column} />
            </h6>
          </th>
        ))}
        <th className="actions" />
      </tr>
    </thead>
  )
}

export default OrderItemsTableHead
