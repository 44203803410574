import { type PropsWithChildren, type ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { type CardProps } from 'react-bootstrap'
import {
  type OrderLineItemResource,
  type OrderResource
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useIsMobile from '../../common/hooks/useIsMobile'
import useBasketCount from '../../basket/hooks/useBasketCount'
import useBasketPreview from '../../basket/hooks/useBasketPreview'
import useBasketSettings from '../../basket/hooks/useBasketSettings'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaToast from '../../common/components/MaToast'
import MaConfirm from '../../common/components/MaConfirm'
import styles from '../assets/scss/OrderCard.module.scss'

interface BuyAgainProps extends CardProps, PropsWithChildren {
  order: OrderResource
  successfulOrderItems: OrderLineItemResource[]
  unsuccessfulOrderItems: OrderLineItemResource[]
  showModal: boolean
  setShowModal: (showModal: boolean) => void
}

function BuyAgainModal ({
  order,
  successfulOrderItems,
  unsuccessfulOrderItems,
  showModal,
  setShowModal
}: BuyAgainProps): ReactElement {
  const { t } = useTranslation()
  const { ordersApi } = useApi()
  const { basketUrl, target } = useBasketSettings()
  const { mutate: updateBasketCount } = useBasketCount()
  const { mutate: updateBasketPreview } = useBasketPreview()
  const [basketPending, setBasketPending] = useState(false)
  const { activeAccount } = useAccounts()
  const isMobile = useIsMobile()

  const validForReorder = unsuccessfulOrderItems.length === 0

  const accountId = activeAccount?.accountId ?? ''
  const orderId = order?.id

  const addedToBasketClassName =
    successfulOrderItems.length === 1
      ? 'order.card.buy_again.added_to_basket_one'
      : 'order.card.buy_again.added_to_basket_other'

  const { showToast, closeToast } = useToastNotification()

  const handleCopyToBasket = async (): Promise<void> => {
    if (!activeAccount?.accountId || !order.id) return

    try {
      setBasketPending(true)
      const response = await ordersApi.reorder({ orderId, accountId })
      await updateBasketCount()
      await updateBasketPreview()

      const successfulLineItemsQty = (
        response.data?.successful_line_items ?? []
      ).length

      const toastId = Date.now()
      if (successfulLineItemsQty > 0) {
        showToast(
          toastId,
          <MaToast
            type="dark"
            onClose={() => {
              closeToast(toastId)
            }}
          >
            <p>
              {t('product.details.add_to_basket.success', {
                count: successfulLineItemsQty
              })}
            </p>
            <Link to={basketUrl} target={target}>
              {t('product.details.add_to_basket.basket_link')}
            </Link>
          </MaToast>
        )
      } else {
        showToast(
          toastId,
          <MaToast
            type="danger"
            onClose={() => {
              closeToast(toastId)
            }}
          >
            <p>{t('product.details.add_to_basket.failure')}</p>
          </MaToast>
        )
      }

      setShowModal(false)
    } catch (e) {
      const toastId = Date.now()
      showToast(
        toastId,
        <MaToast
          type="danger"
          onClose={() => {
            closeToast(toastId)
          }}
        >
          <p>{t('product.details.add_to_basket.failure')}</p>
        </MaToast>
      )
    } finally {
      setBasketPending(false)
    }
  }

  const modalContent = (): ReactElement => {
    if (!validForReorder) {
      return (
        <p className="small">
          {t('order.card.buy_again.inactive_products_warning')}
        </p>
      )
    }

    return (
      <p className="small">
        {t(addedToBasketClassName, {
          count: successfulOrderItems.length,
          poRef: order.reference
        })}
      </p>
    )
  }

  return (
    <>
      <MaConfirm
        size={isMobile ? 'sm' : undefined}
        variant={validForReorder ? 'question' : 'warning'}
        show={showModal}
        title={
          validForReorder
            ? t('order.card.buy_again.confirmation.title')
            : t('common.title.warning')
        }
        closeLabel={t('common.button.labels.cancel')}
        confirmLabel={t('common.button.labels.ok')}
        disabled={basketPending}
        onConfirm={() => {
          void handleCopyToBasket()
        }}
        onClose={() => {
          setShowModal(false)
        }}
        className={styles.confirmation}
      >
        {modalContent()}
        <p className="small">{t('order.card.buy_again.wish_to_continue')}</p>
      </MaConfirm>
    </>
  )
}
export default BuyAgainModal
