import { type HTMLAttributes, type ReactElement } from 'react'
import styles from '../assets/scss/Widget.module.scss'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useIsMobile from '../../common/hooks/useIsMobile'

interface WidgetProps extends HTMLAttributes<HTMLDivElement> {
  value: number
  text: string
  url: string
  isLoading: boolean
}

function Widget ({ value, text, url, isLoading }: WidgetProps): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const isRelativeUrl = url.startsWith('/')

  if (isLoading) {
    return (
      <div className={styles.widget}>
        <LoadingSpinner />
      </div>
    )
  }

  const widget = (
    <div className={classNames(styles.widget, { [styles.mobile]: isMobile })}>
      <p className={classNames(styles.value, { [styles.zero]: value === 0 })}>
        {value}
      </p>
      <p className={styles.text}>{t(text, { count: value })}</p>
    </div>
  )

  return (
    <>
      {value > 0
        ? (
        <Link
          to={url}
          target={isRelativeUrl ? '' : '_blank'}
          rel={isRelativeUrl ? '' : 'noreferrer'}
          className={classNames(styles.link, { [styles.mobile]: isMobile })}
        >
          {widget}
        </Link>
          )
        : (
            widget
          )}
    </>
  )
}

export default Widget
