import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type DateRange } from 'react-day-picker'
import { differenceInYears, format, isToday } from 'date-fns'
import useDateLocale from '../../common/hooks/useDateLocale'
import styles from '../assets/scss/SearchSummary.module.scss'
import { type FacetFilters } from '../types/facet-filters'

interface OrderSearchSummaryProps {
  total?: number
  dateRange?: DateRange
  filters?: FacetFilters
  term?: string
}

function OrderSearchSummary ({
  total,
  dateRange,
  filters,
  term
}: OrderSearchSummaryProps): ReactElement | null {
  const { t } = useTranslation()
  const dateLocale = useDateLocale()

  const formattedDateFrom =
    dateRange?.from &&
    format(dateRange?.from, 'd MMM yyyy', { locale: dateLocale })
  const formattedDateTo =
    dateRange?.to && format(dateRange?.to, 'd MMM yyyy', { locale: dateLocale })

  const statuses = filters?.has('status')
    ? Array.from(filters.get('status') as Set<string>)
    : []
  const managedOrders = filters?.has('managed_order')
    ? Array.from(filters.get('managed_order') as Set<string>)
    : []

  if (total === undefined) {
    return null
  }

  if (
    filters &&
    managedOrders.length === 1 &&
    managedOrders.includes('amici_serviced') &&
    dateRange?.from &&
    dateRange?.to &&
    isToday(dateRange?.to) &&
    differenceInYears(dateRange.to, dateRange.from) === 1
  ) {
    if (filters.size === 1) {
      return (
        <div className={styles.summary}>
          <p>{t('order.search.summary.total', { count: total })}</p>
        </div>
      )
    }

    if (filters.size === 2 && statuses.length === 1) {
      return (
        <div className={styles.summary}>
          <p>{t(`order.search.summary.${statuses[0]}`, { count: total })}</p>
        </div>
      )
    }
  }

  if (total === 0) {
    if (filters?.size || term) {
      return (
        <div className={styles.summary}>
          <p>{t('order.search.summary.no_results')}</p>
        </div>
      )
    }

    if (dateRange) {
      return (
        <div className={styles.summary}>
          <p>{t('order.search.summary.no_results_for_dates')}</p>
        </div>
      )
    }

    return (
      <div className={styles.summary}>
        <p>{t('order.search.summary.no_orders_placed')}</p>
      </div>
    )
  }

  return (
    <div className={styles.summary}>
      <p>
        {!formattedDateFrom &&
          t('order.search.summary.no_date.results', { count: total })}

        {formattedDateFrom &&
          formattedDateTo &&
          formattedDateFrom === formattedDateTo &&
          t('order.search.summary.single_date.results', {
            count: total,
            dateFrom: formattedDateFrom
          })}

        {formattedDateFrom &&
          formattedDateTo &&
          formattedDateFrom !== formattedDateTo &&
          t('order.search.summary.date_range.results', {
            count: total,
            dateFrom: formattedDateFrom,
            dateTo: formattedDateTo
          })}
      </p>
    </div>
  )
}

export default OrderSearchSummary
