import { type ReactElement, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'
import useProductNotes from '../hooks/useProductNotes'
import useAccounts from '../../common/hooks/useAccounts'
import { UserModule } from '../../common/types/user-module'
import styles from '../assets/scss/ProductDetailsTabNotes.module.scss'

const DEBOUNCE_DELAY_MS = 1000
const MAX_NOTE_LENGTH = 800

function ProductDetailsTabNotes ({
  productId
}: {
  productId?: string
}): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()

  const {
    data,
    isLoading,
    isMutating,
    isValidating,
    loadingError,
    mutationError,
    update
  } = useProductNotes(productId)

  const {
    register,
    formState: { errors, isValid, isDirty },
    getValues,
    watch
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      internalPartNumber: '',
      note: ''
    },
    values: {
      internalPartNumber: data?.internal_part_number ?? '',
      note: data?.note ?? ''
    }
  })

  const [isTyping, setIsTyping] = useState(true)
  const touchedForm = useRef(false)

  const isLoaded = data !== undefined && !isLoading

  const internalPartNumber = useDebounce(
    watch('internalPartNumber'),
    DEBOUNCE_DELAY_MS
  )
  const note = useDebounce(watch('note'), DEBOUNCE_DELAY_MS)

  useEffect(() => {
    if (!isDirty) {
      setIsTyping(false)
      return
    }

    if (
      internalPartNumber === getValues('internalPartNumber') &&
      note === getValues('note')
    ) {
      touchedForm.current = true
      setIsTyping(false)
      return
    }

    setIsTyping(true)
  }, [getValues, internalPartNumber, isDirty, note])

  useEffect(() => {
    if (isLoaded && isValid && !isTyping) {
      void update({
        note: getValues('note'),
        internal_part_number: getValues('internalPartNumber')
      })
    }
  }, [getValues, isLoaded, isTyping, isValid, update])

  const isFetching = isLoading || isValidating
  const syncError = loadingError || mutationError

  const showSavingMessage = (touchedForm.current && isMutating) || isDirty
  const showSuccessMessage =
    !isFetching && !isMutating && !syncError && isValid && touchedForm.current
  const showErrorMessage = syncError || !isValid
  const canEditInternalPartNumber = activeAccount?.modules.includes(
    UserModule.HasStockCheckModule
  )

  return (
    <div className={styles['custom-notes-tab']}>
      {!isLoading && (
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
          onChange={() => {
            setIsTyping(true)
          }}
        >
          {canEditInternalPartNumber && (
            <Form.Group>
              <Form.Label>
                {t('product.details.tabs.notes.label.ipn')}
              </Form.Label>
              <Form.Control
                {...register('internalPartNumber', {
                  maxLength: {
                    value: 50,
                    message: t('validation.error.notes.max_length', {
                      count: 50
                    })
                  }
                })}
                isInvalid={!!errors.internalPartNumber}
                className={styles['form-control']}
                aria-label={t('product.details.tabs.notes.label.ipn')}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.internalPartNumber?.message ?? ''}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>
              {t('product.details.tabs.notes.label.notes')}
            </Form.Label>
            <Form.Control
              {...register('note', {
                maxLength: {
                  value: MAX_NOTE_LENGTH,
                  message: t('validation.error.notes.max_length', {
                    count: MAX_NOTE_LENGTH
                  })
                }
              })}
              as="textarea"
              isInvalid={!!errors.note}
              className={styles['form-control']}
              aria-label={t('product.details.tabs.notes.label.notes')}
            />
            <Form.Control.Feedback type="invalid">
              <div className={styles.status}>
                <p>
                  {t('product.details.tabs.notes.status.note_length', {
                    count: getValues('note').length,
                    total: MAX_NOTE_LENGTH
                  })}
                </p>

                {showSuccessMessage && !isDirty && (
                  <p className="text-success">
                    {t('product.details.tabs.notes.status.saved')}
                  </p>
                )}
                {showErrorMessage && !errors?.note?.message && (
                  <p className="text-danger">
                    {t('product.details.tabs.notes.status.error')}
                  </p>
                )}
                {!showErrorMessage && showSavingMessage && (
                  <p className="text-warning">
                    {t('product.details.tabs.notes.status.saving')}
                  </p>
                )}
                {showErrorMessage && errors?.note?.message && (
                  <p className="text-danger">{errors?.note?.message ?? ''}</p>
                )}
              </div>
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </div>
  )
}

export default ProductDetailsTabNotes
