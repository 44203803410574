import useSWR from 'swr'
import { type ReorderResponse } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface useValidateReorderHook {
  data: ReorderResponse
  isLoading: boolean
}

function useValidateReorder (orderId: string): useValidateReorderHook {
  const { ordersApi, fetcher } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR(
    orderId && accountId ? ['validate-reorder', orderId, accountId] : null,
    async () =>
      await fetcher(ordersApi.validateReorder, { orderId, accountId })
  )

  return {
    data: data as ReorderResponse,
    isLoading
  }
}

export default useValidateReorder
