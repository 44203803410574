import { type ReactElement } from 'react'
import { Button, type ButtonProps } from 'react-bootstrap'
import classNames from 'classnames'
import styles from '../assets/scss/MaActionIcon.module.scss'

function MaActionIcon ({
  children,
  className,
  ...props
}: ButtonProps): ReactElement {
  return (
    <Button
      className={classNames(styles['ma-action-icon'], className)}
      {...props}
    >
      {children}
    </Button>
  )
}

export default MaActionIcon
