import { type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import MaConfirm from '../../common/components/MaConfirm'
import { Col, Form, Row } from 'react-bootstrap'
import styles from '../assets/scss/OrderRequestEditDeliveryInfoModal.module.scss'
import { Controller, useForm } from 'react-hook-form'
import useAccounts from '../../common/hooks/useAccounts'
import { formatAddress } from '../../common/utils/format-address'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import { type Address } from '@amici/myamici-api-client'

export interface DeliveryInfo {
  addressId: string
  fao?: string
  note?: string
}

const NOTE_MAX_LENGTH = 1000

function AddressSelect ({
  value,
  onChange,
  addresses,
  disabled
}: {
  value: string
  onChange: (value: string) => void
  addresses: Address[]
  disabled: boolean
}): ReactElement {
  const { t } = useTranslation()

  const selectedAddress = addresses.find(address => address.id === value)
  return (
    <MaSelect
      aria-label={t('order_request.form.label.address')}
      value={value}
      onValueChange={onChange}
      required
      title={formatAddress(selectedAddress)}
      disabled={disabled}
    >
      {addresses.map(address => {
        const formattedAddress = formatAddress(address)
        return (
          <MaSelectItem
            key={address.id}
            value={address.id}
            title={formattedAddress}
          >
            {formattedAddress}
          </MaSelectItem>
        )
      })}
    </MaSelect>
  )
}

interface OrderRequestDeliveryInfoModalProps {
  show: boolean
  onClose: () => void
  onConfirm: (deliveryInfo: DeliveryInfo) => Promise<void>
  deliveryInfo: DeliveryInfo
  disabled: boolean
}

function OrderRequestEditDeliveryInfoModal ({
  show,
  onClose,
  onConfirm,
  deliveryInfo,
  disabled
}: OrderRequestDeliveryInfoModalProps): ReactElement {
  const { t } = useTranslation()
  const {
    register,
    watch,
    formState: { errors, isValid, isDirty },
    control,
    reset,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      note: deliveryInfo.note ?? '',
      addressId: deliveryInfo.addressId,
      fao: deliveryInfo.fao
    }
  })
  const { accountProfile } = useAccounts()

  const handleClose = (): void => {
    reset()
    onClose()
  }
  const handleConfirm = async (): Promise<void> => {
    const values = getValues()
    setValue('fao', values.fao?.trim())
    setValue('note', values.note?.trim())
    await onConfirm(getValues())
  }

  const note = watch('note')
  return (
    <MaConfirm
      title={t('order_request.delivery_info.edit.title')}
      size="lg"
      show={show}
      onConfirm={handleConfirm}
      onClose={handleClose}
      closeLabel={t('common.button.labels.cancel')}
      disabled={disabled || (isDirty && !isValid)}
    >
      <Form className={styles.form}>
        <fieldset disabled={disabled}>
          <Row>
            <Form.Group className="mb-3" as={Col} lg={4} controlId="fao">
              <Form.Label column={false}>
                <Trans i18nKey="order_request.form.label.fao" />
              </Form.Label>
              <Form.Control {...register('fao')} maxLength={100} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col} lg={8} controlId="addressId">
              <Form.Label column={false} className="required">
                <Trans i18nKey="order_request.form.label.address" />
              </Form.Label>
              <Controller
                name="addressId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AddressSelect
                    value={value}
                    onChange={onChange}
                    addresses={accountProfile?.client?.addresses as Address[]}
                    disabled={disabled}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="note-to-supplier">
              <Form.Label column={false}>
                <Trans i18nKey="order_request.delivery_info.note_to_supplier" />
              </Form.Label>
              <Form.Control
                {...register('note', {
                  maxLength: {
                    value: NOTE_MAX_LENGTH,
                    message: t('validation.error.notes.max_length', {
                      count: NOTE_MAX_LENGTH
                    })
                  }
                })}
                as="textarea"
                rows={4}
              />
              <Form.Control.Feedback
                type={errors.note ? 'invalid' : 'valid'}
                className={styles['note-feedback']}
              >
                <span>
                  <Trans
                    i18nKey="order_request.delivery_info.edit.note_length"
                    values={{ count: note.length, limit: NOTE_MAX_LENGTH }}
                  />
                </span>
                {errors.note && <span>{errors.note?.message}</span>}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestEditDeliveryInfoModal
