import { type HTMLAttributes, type ReactElement } from 'react'
import useSupplier from '../hooks/useSupplier'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaModal from '../../common/components/MaModal'
import { type TFunction } from 'i18next'
import { urlRegex } from '../../common/utils/regex-patterns'
import { SupplierStatusEnum } from '@amici/myamici-api-client'
import styles from '../assets/scss/ProductDetails.module.scss'

interface SupplierSummaryProps extends HTMLAttributes<HTMLDivElement> {
  supplierId: string | null | undefined
  showSummary: boolean
  setShowSummary: (showSummary: boolean) => void
}

function getSupplierNameUrl (
  name: string | null | undefined,
  url: string | null | undefined
): ReactElement {
  let webUrl: string = url?.trim() ?? ''

  if (
    name &&
    name.length > 0 &&
    webUrl.length > 0 &&
    !webUrl.includes(' ') &&
    urlRegex.test(webUrl)
  ) {
    if (!webUrl.startsWith('http')) {
      webUrl = 'http://'.concat(webUrl)
    }

    return (
      <a href={webUrl} target="_blank" rel="noreferrer">
        {name}
      </a>
    )
  } else if (name && name.length > 0) {
    return <>{name}</>
  }

  return <>-</>
}

function getSupplierSummaryValue (value: string | null | undefined): string {
  if (!value || value.trim().length === 0) {
    return '-'
  }

  return value.trim()
}

function getSupplierSummaryCountry (
  t: TFunction<any, any>,
  value: number | null | undefined
): string {
  if (!value) {
    return '-'
  }

  return t(`country.codes.${value.toString()}`)
}

function SupplierSummaryModal ({
  supplierId,
  showSummary,
  setShowSummary
}: SupplierSummaryProps): ReactElement {
  const { t } = useTranslation()
  const { isLoading, error, data: supplierSummary } = useSupplier(supplierId)

  if (isLoading || error) {
    return <LoadingSpinner />
  }

  const supplierStatus: string | undefined =
    supplierSummary?.status !== SupplierStatusEnum.INACTIVE
      ? 'active'
      : 'inactive'

  return (
    <MaModal
      size="lg"
      data-testid="supplier-summary-modal"
      show={showSummary}
      title={t('product.details.supplier.supplier_summary.title')}
      onClose={() => {
        setShowSummary(false)
      }}
    >
      <Row>
        <Col lg={3} xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.supplier')}
        </Col>
        <Col lg={9} xs={6} className="py-1">
          {getSupplierNameUrl(supplierSummary?.name, supplierSummary?.website)}
        </Col>
      </Row>
      <Row>
        <Col lg={3} xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.abbreviation')}
        </Col>
        <Col lg={3} xs={6} className="py-1">
          {getSupplierSummaryValue(supplierSummary?.abbreviation)}
        </Col>
        <Col lg={2} xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.address')}
        </Col>
        <Col lg={4} xs={6} className="py-1">
          <div className={styles['text-field-container']}>
            {getSupplierSummaryValue(supplierSummary?.address)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={3} xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.company_reg_number')}
        </Col>
        <Col lg={3} xs={6} className="py-1">
          {getSupplierSummaryValue(supplierSummary?.company_reg_number)}
        </Col>
        <Col lg={2} xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.country')}
        </Col>
        <Col lg={4} xs={6} className="py-1">
          {getSupplierSummaryCountry(t, supplierSummary?.country)}
        </Col>
      </Row>
      <Row>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.status')}
        </Col>
        <Col lg xs={6} className="py-1">
          {t('common.status.values.' + `${supplierStatus ?? ''}`, '-')}
        </Col>
        <Col lg xs={6} className="py-1"></Col>
        <Col lg xs={6}></Col>
      </Row>

      <hr />

      <Row>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.carriage_policy')}
        </Col>
        <Col lg xs={6} className="py-1">
          <div className={styles['text-field-container']}>
            {getSupplierSummaryValue(supplierSummary?.carriage_policy)}
          </div>
        </Col>
        <Col lg xs={6} className="py-1">
          {t(
            'product.details.supplier.supplier_summary.standard_carriage_charge'
          )}
        </Col>
        <Col lg xs={6} className="py-1">
          {t('product.card.price', {
            price: supplierSummary?.standard_carriage_charge,
            currency: supplierSummary?.currency
          })}
        </Col>
      </Row>

      <hr />

      <Row>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.order_method')}
        </Col>
        <Col lg xs={6} className="py-1">
          {t(
            'product.details.supplier.supplier_summary.order_method_' +
              `${supplierSummary?.order_method?.toLowerCase() ?? ''}`,
            '-'
          )}
        </Col>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.min_order_threshold')}
        </Col>
        <Col lg xs={6} className="py-1">
          {t('product.card.price', {
            price: supplierSummary?.min_order_threshold,
            currency: supplierSummary?.currency
          })}
        </Col>
      </Row>
      <Row>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.consolidate_orders')}
        </Col>
        <Col lg xs={6} className="py-1">
          {supplierSummary?.consolidate_orders === true
            ? t('common.values.yes')
            : t('common.values.no')}
        </Col>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.min_order_charge')}
        </Col>
        <Col lg xs={6} className="py-1">
          {t('product.card.price', {
            price: supplierSummary?.min_order_charge,
            currency: supplierSummary?.currency
          })}
        </Col>
      </Row>
      <Row>
        <Col lg xs={6} className="py-1">
          {t('product.details.supplier.supplier_summary.order_cutoff_time')}
        </Col>
        <Col lg xs={6} className="py-1">
          {getSupplierSummaryValue(supplierSummary?.order_cutoff_time)}
        </Col>
        <Col lg xs={6} className="py-1"></Col>
        <Col lg xs={6}></Col>
      </Row>
    </MaModal>
  )
}

export default SupplierSummaryModal
