import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProductStockAvailabilityEnum,
  type ProductResource
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import useStock from '../hooks/useStock'
import styles from '../assets/scss/ProductDetailsStockInfo.module.scss'

function ProductDetailsStockInfo ({
  product
}: {
  product: ProductResource
}): ReactElement | null {
  const { t } = useTranslation()

  const { availability, minimumQuantity, maximumQuantity, isLoading } =
    useStock(product.id)

  if (product.is_self_serve) {
    return (
      <div
        className={classNames(styles.stock, styles.disabled, 'text-truncate')}
      >
        {t('product.stock.status.self_service')}
      </div>
    )
  }

  if (product.status === 'inactive') {
    return (
      <div
        className={classNames(styles.stock, styles.disabled, 'text-truncate')}
      >
        {t('product.stock.status.inactive')}
      </div>
    )
  }

  const variant = (
    {
      [ProductStockAvailabilityEnum.UNAVAILABLE]: 'warning',
      [ProductStockAvailabilityEnum.EXACT]: 'success',
      [ProductStockAvailabilityEnum.LESS_THAN]: 'success',
      [ProductStockAvailabilityEnum.MORE_THAN]: 'success',
      [ProductStockAvailabilityEnum.RANGE]: 'success',
      [ProductStockAvailabilityEnum.NO_STOCK]: 'danger'
    } satisfies Record<ProductStockAvailabilityEnum, string>
  )[availability ?? ProductStockAvailabilityEnum.UNAVAILABLE]

  let stockInfo = t('product.stock.status.loading')
  if (!isLoading) {
    stockInfo = (
      {
        [ProductStockAvailabilityEnum.EXACT]: t(
          'product.stock.stock_value.exact',
          { count: maximumQuantity ?? 0 }
        ),
        [ProductStockAvailabilityEnum.RANGE]: t(
          'product.stock.stock_value.range',
          {
            minimum: minimumQuantity,
            maximum: maximumQuantity
          }
        ),
        [ProductStockAvailabilityEnum.LESS_THAN]: t(
          'product.stock.stock_value.less_than',
          { value: maximumQuantity }
        ),
        [ProductStockAvailabilityEnum.MORE_THAN]: t(
          'product.stock.stock_value.more_than',
          { value: minimumQuantity }
        ),
        [ProductStockAvailabilityEnum.NO_STOCK]: t(
          'product.stock.stock_value.no_stock'
        ),
        [ProductStockAvailabilityEnum.UNAVAILABLE]: t(
          'product.stock.stock_value.unavailable'
        )
      } satisfies Record<ProductStockAvailabilityEnum, string>
    )[availability ?? ProductStockAvailabilityEnum.UNAVAILABLE]
  }

  return (
    <div className={classNames(styles.stock, styles[variant], 'text-truncate')}>
      {stockInfo}
    </div>
  )
}

export default ProductDetailsStockInfo
