import { useEffect, type HTMLAttributes, type ReactElement } from 'react'
import { type LineItem } from '@amici/myamici-api-client'
import { Button, Form, FormGroup, Table } from 'react-bootstrap'
import {
  Controller,
  type Control,
  type UseFormRegister,
  type FieldErrors,
  type UseFormTrigger,
  type UseFormWatch
} from 'react-hook-form'
import { formatISO } from 'date-fns'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import MaDatePicker from '../../common/components/MaDatePicker'
import { type ReceiveLineItemsFormValues } from './ReceiveLineItemsModal'
import styles from '../assets/scss/ReceiveLineItemsModal.module.scss'

type FormMode = 'receive' | 'unreceive'

interface ReceiveLineItemFieldsetProps extends HTMLAttributes<HTMLElement> {
  variant: 'item' | 'order'
  mode?: FormMode
  lineItem: LineItem
  index: number
  quantityDisabled: boolean
  register: UseFormRegister<ReceiveLineItemsFormValues>
  watch: UseFormWatch<ReceiveLineItemsFormValues>
  control: Control<ReceiveLineItemsFormValues, any>
  errors: FieldErrors<ReceiveLineItemsFormValues>
  container?: HTMLElement
  triggerValidation?: UseFormTrigger<ReceiveLineItemsFormValues>
  onModeChange?: (mode: FormMode) => void
}

function ReceiveLineItemFieldset ({
  variant,
  mode = 'receive',
  index,
  lineItem,
  register,
  watch,
  control,
  errors,
  className,
  quantityDisabled,
  container,
  triggerValidation,
  onModeChange,
  ...props
}: ReceiveLineItemFieldsetProps): ReactElement {
  const { t } = useTranslation()

  useEffect(() => {
    void triggerValidation?.()
  }, [mode, triggerValidation])

  const outstandingQuantity =
    (lineItem?.quantity ?? 0) - (lineItem?.quantity_received ?? 0)

  const packSize = lineItem.product.pack_size ?? 0
  const packSizeValue =
    ((lineItem.product.no_of_units ?? 0) > 1
      ? `${lineItem.product.no_of_units} x `
      : '') + packSize.toString()
  const packSizeUnit: string = lineItem.product.pack_size_unit ?? ''

  const isFullyReceived = outstandingQuantity === 0

  const showQuantityControl =
    (!isFullyReceived && mode === 'receive') || mode === 'unreceive'
  const showDateControl = !isFullyReceived && mode === 'receive'
  const showToggleUnreceiveButton = isFullyReceived && mode === 'receive'
  const canEditQuantity =
    !isFullyReceived &&
    variant === 'item' &&
    mode === 'receive' &&
    (lineItem?.quantity_received ?? 0) > 0

  const handleToggleEditQuantity = (): void => {
    if (canEditQuantity) {
      onModeChange?.('unreceive')
    }
  }

  const isDateRequired = watch(`lineItems.${index}.quantity`) > 0

  return (
    <div className={classNames(styles['line-item'], className)} {...props}>
      <h6 className={styles.description}>{lineItem?.product.description}</h6>

      <div className={styles.content}>
        <div className={styles.info}>
          <Table className={styles.table}>
            <tbody>
              <tr>
                <th>{t('receive_order.item.part_number')}</th>
                <td title={lineItem?.product.part_number ?? '-'}>
                  {lineItem?.product.part_number ?? '-'}
                </td>
              </tr>
              <tr>
                <th>{t('receive_order.item.pack_size')}</th>
                <td>
                  {t('order.item.card.pack_size_value', {
                    packSizeValue,
                    packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                      count: +packSize
                    })
                  })}
                </td>
              </tr>
            </tbody>
          </Table>

          <Table className={styles.table}>
            <tbody>
              <tr>
                <th>{t('receive_order.item.ordered')}</th>
                <td>{lineItem?.quantity ?? '-'}</td>
              </tr>
              <tr>
                <th>{t('receive_order.item.received')}</th>
                <td>
                  <span
                    className={classNames({
                      [styles['toggle-edit']]: canEditQuantity
                    })}
                    onClick={handleToggleEditQuantity}
                    title={
                      canEditQuantity
                        ? t('receive_order.form.label.reduce_received_quantity')
                        : ''
                    }
                  >
                    {lineItem?.quantity_received ?? '-'}
                  </span>
                </td>
              </tr>
              <tr>
                <th>{t('receive_order.item.outstanding')}</th>
                <td>{outstandingQuantity}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <section className={styles.controls}>
          <input
            type="hidden"
            {...register(`lineItems.${index}.line_item_id`)}
          />

          {showQuantityControl && (
            <FormGroup
              className={classNames(styles.control, styles.quantity)}
              controlId={`lineItems.${index}.quantity`}
            >
              {mode === 'receive' && (
                <Form.Label>
                  {t('receive_order.form.label.quantity')}
                </Form.Label>
              )}
              {mode === 'unreceive' && (
                <Form.Label className="text-warning">
                  {t('receive_order.form.label.remove')}
                </Form.Label>
              )}
              <Form.Control
                type="number"
                disabled={quantityDisabled}
                {...register(`lineItems.${index}.quantity`, {
                  valueAsNumber: true,
                  max:
                    mode === 'receive'
                      ? outstandingQuantity
                      : lineItem.quantity_received,
                  min: 0,
                  required: true
                })}
                max={
                  mode === 'receive'
                    ? outstandingQuantity
                    : lineItem.quantity_received
                }
                min={0}
                isInvalid={!!errors?.lineItems?.[index]?.quantity}
              />
            </FormGroup>
          )}

          {showDateControl && (
            <FormGroup
              className={classNames(styles.control, styles.date)}
              controlId={`lineItems.${index}.date`}
            >
              <Form.Label>{t('receive_order.form.label.date')}</Form.Label>
              <Controller
                control={control}
                name={`lineItems.${index}.date`}
                rules={{
                  required: isDateRequired
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <MaDatePicker
                    container={container}
                    selected={value ? new Date(value) : undefined}
                    onDayBlur={onBlur}
                    disabled={{ after: new Date() }}
                    isInvalid={
                      isDateRequired && !watch(`lineItems.${index}.date`)
                    }
                    onSelect={day => {
                      onChange(
                        day ? formatISO(day, { representation: 'date' }) : null
                      )
                    }}
                  />
                )}
              />
            </FormGroup>
          )}

          {showToggleUnreceiveButton && (
            <FormGroup
              className={classNames(styles.control, styles['toggle-unreceive'])}
            >
              <Button
                variant="outline-primary"
                className="rounded"
                onClick={() => {
                  onModeChange?.('unreceive')
                }}
              >
                {t('receive_order.form.label.reduce_received_quantity')}
              </Button>
            </FormGroup>
          )}
        </section>
      </div>
    </div>
  )
}

export default ReceiveLineItemFieldset
