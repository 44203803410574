import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductSortType } from '../types/product-sort-type'
import {
  MaSelect,
  MaSelectItem,
  type MaSelectProps
} from '../../common/components/MaSelect'

function ProductSearchSortSelect (props: MaSelectProps): ReactElement {
  const { t } = useTranslation()

  return (
    <MaSelect
      placeholder={t('common.label.sort_by')}
      label={t('common.label.sort_by')}
      {...props}
    >
      <MaSelectItem value={ProductSortType.Default}>
        {t('product.search.sort.default')}
      </MaSelectItem>
      <MaSelectItem value={ProductSortType.PriceLowToHigh}>
        {t('product.search.sort.price.low_to_high')}
      </MaSelectItem>
      <MaSelectItem value={ProductSortType.PriceHighToLow}>
        {t('product.search.sort.price.high_to_low')}
      </MaSelectItem>
      <MaSelectItem value={ProductSortType.UnitPriceLowToHigh}>
        {t('product.search.sort.unit_price.low_to_high')}
      </MaSelectItem>
      <MaSelectItem value={ProductSortType.UnitPriceHighToLow}>
        {t('product.search.sort.unit_price.high_to_low')}
      </MaSelectItem>
    </MaSelect>
  )
}

export default ProductSearchSortSelect
