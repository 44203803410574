import { type PropsWithChildren, type ReactElement, useState } from 'react'
import { type OrderResource } from '@amici/myamici-api-client'
import { Trans, useTranslation } from 'react-i18next'
import { type CardProps, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { format } from 'date-fns'
import {
  BsChevronDoubleDown,
  BsChevronDoubleUp,
  BsExclamationCircle
} from 'react-icons/bs'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import useOrderItems from '../hooks/useOrderItems'
import MaCard from '../../common/components/MaCard'
import useIsMobile from '../../common/hooks/useIsMobile'
import useResizeObserver from 'use-resize-observer'
import useValidateReorder from '../hooks/useValidateReorder'
import MaTooltip from '../../common/components/MaTooltip'
import FallbackImage from '../../common/components/FallbackImage'
import BuyAgainModal from './BuyAgainModal'
import OrderLineItems from './OrderLineItems'
import styles from '../assets/scss/OrderCard.module.scss'

interface OrderCardProps extends CardProps, PropsWithChildren {
  order: OrderResource
}

function OrderCard ({
  order,
  className,
  ...props
}: OrderCardProps): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { data, isLoading, estimatedDeliveryDate } = useOrderItems(order.id)
  const { data: validateReorderData, isLoading: isValidateReorderLoading } =
    useValidateReorder(order.id)
  const orderItems = data?.content ?? []
  const maxLineItems = isMobile ? 5 : 10
  const successfulReorderItems =
    validateReorderData?.successful_line_items ?? []
  const unsuccessfulReorderItems =
    validateReorderData?.unsuccessful_line_items ?? []

  const { height: expandableHeight, ref: expandableRef } = useResizeObserver({
    box: 'border-box'
  })
  const [expanded, setExpanded] = useState<boolean>(false)

  const [showModal, setShowModal] = useState<boolean>(false)

  const orderViewUrl = `/purchasing/orders/${order.id}`

  const totalPrice =
    (order?.carriage_charge ?? 0) +
    (order?.order_charge ?? 0) +
    orderItems.reduce(
      (total, item) => total + (item?.price ?? 0) * item.quantity,
      0
    )

  const images = data?.content
    ?.filter(item => item.image_url || item.product.smiles)
    .sort((a, b) => (a.image_url ? 1 : 0) - (b.image_url ? 1 : 0))
    .slice(0, 3)
    .map((item, index) => {
      if (item.image_url) {
        return (
          <FallbackImage
            key={index}
            className={styles.img}
            src={item.image_url}
            loading="lazy"
            thumbnail
          />
        )
      }
      return (
        <SmilesSvgRenderer
          key={index}
          width={88}
          height={88}
          smiles={item.product.smiles ?? ''}
          ErrorComponent={FallbackImage}
        />
      )
    })

  const issues = Array.from(
    new Set(data?.content?.flatMap(item => item.issues))
  )
    .map(issue =>
      issue === 'on_hold'
        ? t('order.items.issue.on_hold_no_date')
        : t(`order.items.issue.${issue as string}`)
    )
    .join(', ')

  const canReorder =
    isValidateReorderLoading ||
    successfulReorderItems.length === 0 ||
    successfulReorderItems.some(s => s.is_self_serve)

  return (
    <>
      <MaCard
        className={classNames(styles['order-card'], className)}
        {...props}
      >
        <MaCard.Body>
          <div className={styles.content}>
            <div className={styles['left-col']}>
              <Link to={orderViewUrl} className={styles.title}>
                {t(`order.status.${order.status ?? ''}`, {
                  date:
                    estimatedDeliveryDate &&
                    format(estimatedDeliveryDate, 'do MMMM yyyy')
                })}
              </Link>
              <p data-testid="order-reference">
                {order.confidential && (
                  <b>{t('order.card.confidential_order')} </b>
                )}
                {order.reference} -{' '}
                {t('order.card.total_price', {
                  price: totalPrice,
                  currency: order.currency ?? 'GBP'
                })}
              </p>
              <p>
                <Trans
                  i18nKey="order.card.ordered_by"
                  values={{
                    name: order.ordered_by?.name,
                    date: format(
                      new Date(order.order_date ?? 0),
                      'do MMMM yyyy'
                    )
                  }}
                  components={{ bold: <b /> }}
                />
              </p>
              <div>{order.supplier?.name && <p>{order.supplier.name}</p>}</div>
            </div>
            <div className={styles['right-col']}>
              {(images?.length as number) > 0 && (
                <div
                  className={classNames(styles.images, {
                    [styles.expanded]: expanded
                  })}
                >
                  {images?.map(image => image)}
                </div>
              )}
              {!isLoading && (
                <div className={styles.text}>
                  <div
                    className={classNames(styles['line-item-count'], {
                      [styles.expanded]: expanded || !issues
                    })}
                    onClick={() => {
                      setExpanded(currentValue => !currentValue)
                    }}
                  >
                    {expanded && orderItems.length > maxLineItems
                      ? t('order.card.line_item_count_limited', {
                        limit: maxLineItems,
                        count: orderItems.length
                      })
                      : t('order.card.line_item_count', {
                        count: orderItems.length
                      })}{' '}
                    {expanded
                      ? (
                      <BsChevronDoubleUp size={16} />
                        )
                      : (
                      <BsChevronDoubleDown size={16} />
                        )}
                  </div>
                  {issues && (
                    <MaTooltip
                      placement={'left'}
                      text={
                        <div className={styles['tooltip-text']}>{issues}</div>
                      }
                      className={classNames(styles.tooltip, {
                        [styles.expanded]: expanded
                      })}
                    >
                      <BsExclamationCircle size={16} />
                    </MaTooltip>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(styles['expandable-wrapper'], {
              [styles.expanded]: expanded
            })}
            style={{
              height: expanded ? expandableHeight : 0
            }}
          >
            <div ref={expandableRef} className={styles.expandable}>
              <OrderLineItems
                items={data?.content}
                variant={'summary'}
                max={maxLineItems}
              />
              {orderItems.length > maxLineItems && (
                <Link to={orderViewUrl} target="_blank">
                  {t('order.card.view_full_order')}
                </Link>
              )}
              <Button
                variant="primary"
                className="rounded"
                disabled={canReorder}
                onClick={() => {
                  setShowModal(true)
                }}
              >
                {t('order.card.button.buy_again')}
              </Button>
            </div>
          </div>
        </MaCard.Body>
      </MaCard>
      {showModal && (
        <BuyAgainModal
          order={order}
          successfulOrderItems={successfulReorderItems}
          unsuccessfulOrderItems={unsuccessfulReorderItems}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  )
}

export default OrderCard
