import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'

interface OrderItemActionsProps {
  canReceiveItems: boolean
  onQuickReceiveAction: () => void
  onReceiveAndEditAction: () => void
}

function OrderItemActions ({
  canReceiveItems,
  onQuickReceiveAction,
  onReceiveAndEditAction
}: OrderItemActionsProps): ReactElement {
  const { t } = useTranslation()

  return (
    <MaActionMenu>
      <MaActionMenuItem
        onClick={onQuickReceiveAction}
        disabled={!canReceiveItems}
      >
        {t('order.item.action.quick_receive')}
      </MaActionMenuItem>
      <MaActionMenuItem
        onClick={onReceiveAndEditAction}
        disabled={!canReceiveItems}
      >
        {t('order.item.action.receive_and_edit')}
      </MaActionMenuItem>
    </MaActionMenu>
  )
}

export default OrderItemActions
