import { type ReactElement, type HTMLAttributes } from 'react'
import { type OrderLineItemResource } from '@amici/myamici-api-client'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'
import OrderLineItems from './OrderLineItems'
import { useTranslation } from 'react-i18next'

function PurchaseOrderOrderItems ({
  lineItems,
  ...props
}: HTMLAttributes<HTMLElement> & {
  lineItems: OrderLineItemResource[] | undefined
}): ReactElement {
  const { t } = useTranslation()

  return (
    <MaPanel className={props.className} {...props}>
      <MaPanel.Header>
        <h5 className={styles.title}>
          {t('purchase_order.order_items.title', {
            count: lineItems?.length ?? 0
          })}
        </h5>
      </MaPanel.Header>
      <MaPanel.Body>
        <OrderLineItems items={lineItems} max={10} showAllOption={true} />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderOrderItems
