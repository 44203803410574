import { useCallback } from 'react'
import {
  type BasketLineItems,
  type BasketLineItem,
  type BasketApiDeleteLineItemFromBasketRequest,
  type BasketApiUpdateBasketLineItemRequest,
  type BasketApiClearBasketRequest
} from '@amici/myamici-api-client'
import useSWR, { type KeyedMutator } from 'swr'
import useSWRMutation from 'swr/mutation'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useBasketCount from './useBasketCount'
import useBasketPreview from './useBasketPreview'
import ReactGA from 'react-ga4'

interface UseBasketHook {
  items: BasketLineItem[]
  suppliers: string[]
  mutate: KeyedMutator<BasketLineItems>
  error?: Error
  isLoading: boolean
  isRemoving: boolean
  isValidating: boolean
  isClearing: boolean
  remove: (uuid: string) => Promise<void>
  update: (basketLineItem: BasketLineItem) => Promise<void>
  removeAll: () => Promise<void>
  clearCache: () => void
}

function useBasket (): UseBasketHook {
  const { activeAccount } = useAccounts()
  const {
    basketApi: {
      getBasketLineItems,
      deleteLineItemFromBasket,
      updateBasketLineItem,
      clearBasket
    },
    fetcher
  } = useApi()
  const { mutate: updateBasketPreview } = useBasketPreview()
  const { mutate: updateBasketCount } = useBasketCount()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading, mutate, isValidating } = useSWR<
  BasketLineItems,
  Error
  >(
    accountId ? ['basket-items', accountId] : null,
    async () =>
      await fetcher(getBasketLineItems, {
        accountId,
        page: 1,
        size: 0
      })
  )

  const items = data?.content ?? []

  const suppliers = [
    ...items.reduce(
      (suppliers, item) =>
        suppliers.add(item.line_item?.product.supplier?.name ?? ''),
      new Set<string>()
    )
  ]
    .filter(s => !!s)
    .sort((a, b) => a.localeCompare(b))

  const { trigger: triggerRemove, isMutating: isRemoving } = useSWRMutation(
    accountId ? ['basket-items', accountId] : null,
    async (_, { arg }: { arg: BasketApiDeleteLineItemFromBasketRequest }) => {
      await fetcher(deleteLineItemFromBasket, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerUpdate } = useSWRMutation(
    accountId ? ['basket-items', accountId] : null,
    async (_, { arg }: { arg: BasketApiUpdateBasketLineItemRequest }) =>
      await fetcher(updateBasketLineItem, arg),
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerRemoveAll, isMutating: isClearing } = useSWRMutation(
    accountId ? ['basket-items', accountId] : null,
    async (_, { arg }: { arg: BasketApiClearBasketRequest }) => {
      await fetcher(clearBasket, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const remove = async (uuid: string): Promise<void> => {
    if (!accountId) {
      return
    }

    await triggerRemove({ uuid, accountId })
    await updateBasketPreview()
    await updateBasketCount()
  }

  const update = async (basketLineItem: BasketLineItem): Promise<void> => {
    if (!accountId) {
      return
    }

    await triggerUpdate({
      basketLineItem,
      uuid: basketLineItem.line_item.id,
      accountId
    })
    await updateBasketPreview()
  }

  const removeAll = async (): Promise<void> => {
    if (!accountId) {
      return
    }

    ReactGA.event('action_item_list', {
      item_list_id: 'basket_items',
      action: 'delete'
    })

    await triggerRemoveAll({ accountId })
    await updateBasketPreview()
    await updateBasketCount()
  }

  const clearCache = useCallback((): void => {
    void mutate(undefined)
  }, [mutate])

  return {
    items,
    suppliers,
    mutate,
    remove,
    update,
    removeAll,
    clearCache,
    error,
    isLoading,
    isValidating,
    isRemoving,
    isClearing
  }
}

export default useBasket
