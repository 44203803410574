import { type PropsWithChildren, type ReactElement } from 'react'
import { Col, Row } from 'react-bootstrap'

function MaPageTitle ({ children }: PropsWithChildren): ReactElement {
  return (
    <Row className="mb-3">
      <Col sm={12}>
        <h1 className="title">{children}</h1>
      </Col>
    </Row>
  )
}

export default MaPageTitle
