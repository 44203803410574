import useSWR from 'swr'

interface UserSession {
  CURRENT_ACCOUNT: string
}

interface UseSharedSessionHook {
  isLoading: boolean
  userSessionData: UserSession
}

function useSharedSession (sid = ''): UseSharedSessionHook {
  const { data: userSessionData, isLoading } = useSWR(
    sid ? ['redis-session', sid] : null,
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_ENV_API_HOST ?? ''}/api/shared-session`,
        {
          method: 'POST',
          body: JSON.stringify({ sid: sid ?? '' })
        }
      )
      const data = await response.json()
      return data
    }
  )

  return {
    userSessionData,
    isLoading
  }
}

export default useSharedSession
