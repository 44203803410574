import { type HTMLAttributes, type ReactElement } from 'react'
import useStock from '../hooks/useStock'
import ProductStockMessage from '../../common/components/ProductStockMessage'

interface ProductStockProps extends HTMLAttributes<HTMLDivElement> {
  productId: string
  isSelfServe: boolean
  status: string
}

function ProductStock ({
  productId,
  isSelfServe,
  status,
  className
}: ProductStockProps): ReactElement {
  const {
    availability,
    minimumQuantity,
    maximumQuantity,
    leadTime,
    estimatedShippingDate,
    isLoading
  } = useStock(productId)

  return (
    <ProductStockMessage
      availability={availability}
      minimumQuantity={minimumQuantity}
      maximumQuantity={maximumQuantity}
      leadTime={leadTime}
      estimatedShippingDate={estimatedShippingDate}
      status={status}
      isSelfServe={isSelfServe}
      isLoading={isLoading}
      className={className}
    />
  )
}

export default ProductStock
