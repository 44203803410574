import { type HTMLAttributes, type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BsExclamationCircle } from 'react-icons/bs'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import FallbackImage from '../../common/components/FallbackImage'
import { type OrderLineItemResource } from '@amici/myamici-api-client'
import { format } from 'date-fns'
import useDateLocale from '../../common/hooks/useDateLocale'
import useIsMobile from '../../common/hooks/useIsMobile'
import useResizeObserver from 'use-resize-observer'
import MaTooltip from '../../common/components/MaTooltip'
import styles from '../assets/scss/OrderLineItem.module.scss'

interface OrderLineItemProps extends HTMLAttributes<HTMLDivElement> {
  item: OrderLineItemResource
  variant?: 'full' | 'summary'
}

function DeliveryInfo ({ item }: { item: OrderLineItemResource }): ReactElement {
  const dateLocale = useDateLocale()

  if (item.quantity_received === item.quantity) {
    const receivedDateFormatted = item.date_received
      ? format(new Date(item.date_received), 'do MMM yyyy', {
        locale: dateLocale
      })
      : null

    return (
      <Trans
        i18nKey="order.item.card.delivery_and_receipt.completed"
        values={{
          count: item.quantity,
          date: receivedDateFormatted
        }}
        components={[<span className={styles.subtle} key="subtle" />]}
      />
    )
  }

  if (item.estimated_delivery_date) {
    const dueDateFormatted = item.estimated_delivery_date
      ? format(new Date(item.estimated_delivery_date), 'do MMM yyyy', {
        locale: dateLocale
      })
      : null

    return (
      <Trans
        i18nKey="order.item.card.delivery_and_receipt.due"
        values={{
          count: item.quantity - (item.quantity_received ?? 0),
          date: dueDateFormatted
        }}
        components={[
          <span className={styles.subtle} key="subtle" />,
          item.tracking_url
            ? (
            <Link
              className={styles['tracking-link']}
              key="tracking-link"
              to={item.tracking_url ?? ''}
              target="_blank"
            />
              )
            : (
            <strong key="tracking-link" />
              )
        ]}
      />
    )
  }

  return (
    <Trans
      i18nKey="order.item.card.delivery_and_receipt.awaiting_info"
      values={{
        count: item.quantity - (item.quantity_received ?? 0)
      }}
      components={[<span className={styles.subtle} key="subtle" />]}
    />
  )
}

function OrderLineItem ({ item, variant }: OrderLineItemProps): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { width: lineItemWidth = 0, ref: lineItemRef } = useResizeObserver()
  const FULL_LINE_ITEM_MIN_WIDTH_PX = 250
  const showImage = lineItemWidth > FULL_LINE_ITEM_MIN_WIDTH_PX

  const issues =
    item.issues?.length && item.issues.length > 0
      ? item.issues
        ?.map(issue =>
          t(`order.items.issue.${issue}`, {
            on_hold_date: new Date(item.on_hold_date ?? ''),
            formatParams: {
              on_hold_date: {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
              }
            }
          })
        )
        .join(', ')
      : ''

  const packSize = item.product.pack_size ?? 0
  const packSizeValue =
    ((item.product.no_of_units ?? 0) > 1
      ? `${item.product.no_of_units} x `
      : '') + packSize.toString()
  const packSizeUnit: string = item.product.pack_size_unit ?? ''

  if (variant === 'summary') {
    return (
      <div
        className={classNames(styles['line-item'], styles.summary)}
        ref={lineItemRef}
      >
        {showImage && (
          <div className={styles.img}>
            {item.image_url && (
              <FallbackImage
                className={styles.img}
                src={item.image_url ?? ''}
                loading="lazy"
                thumbnail
              />
            )}
            {!item.image_url && item.product.smiles && (
              <SmilesSvgRenderer
                width={88}
                height={88}
                smiles={item.product?.smiles ?? ''}
                ErrorComponent={FallbackImage}
              />
            )}
          </div>
        )}
        <div
          className={classNames(styles.content, styles.summary, {
            [styles['image-hidden']]: !showImage
          })}
        >
          <p>
            <Link to={`/purchasing/products/${item.product.id}`}>
              {item.product.description}
            </Link>
            {issues && (
              <MaTooltip
                placement={'top'}
                text={<div className={styles['tooltip-text']}>{issues}</div>}
                className={styles.tooltip}
              >
                <BsExclamationCircle size={16} />
              </MaTooltip>
            )}
          </p>
          <div
            className={classNames(styles['middle-row'], {
              [styles.mobile]: isMobile
            })}
          >
            <p>{item.product.part_number}</p>
            <p>
              {t('order.card.line_item.price', {
                price: item.price,
                currency: item.currency ?? 'GBP'
              })}
            </p>
          </div>
          <div
            className={classNames(styles['bottom-row'], {
              [styles.mobile]: isMobile
            })}
          >
            <p>
              {t('order.card.line_item.quantity')} {item.quantity}
            </p>
            <p>
              <DeliveryInfo item={item} />
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles['line-item']} ref={lineItemRef}>
      <div className={styles.title}>
        <Link to={`/purchasing/products/${item.product.id}`}>
          <div className={styles.name}>{item.product.description}</div>
        </Link>
        {issues && (
          <MaTooltip
            placement={'top'}
            text={<div className={styles['tooltip-text']}>{issues}</div>}
            className={styles.tooltip}
          >
            <BsExclamationCircle size={16} />
          </MaTooltip>
        )}
      </div>
      <div
        className={classNames(styles.content, {
          [styles['image-hidden']]: !showImage
        })}
      >
        <div className={styles.details}>
          {showImage && (
            <div className={styles.img}>
              {item.image_url && (
                <FallbackImage
                  className={styles.img}
                  src={item.image_url ?? ''}
                  loading="lazy"
                  thumbnail
                />
              )}
              {!item.image_url && item.product.smiles && (
                <SmilesSvgRenderer
                  width={88}
                  height={88}
                  smiles={item.product?.smiles ?? ''}
                  ErrorComponent={FallbackImage}
                />
              )}
            </div>
          )}

          <table>
            <tbody>
              <tr>
                <th>{t('order.item.card.part_number')}</th>
                <td>{item.product.part_number}</td>
              </tr>
              <tr>
                <th>{t('order.item.card.pack_size')}</th>
                <td>
                  {t('order.item.card.pack_size_value', {
                    packSizeValue,
                    packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                      count: +packSize
                    })
                  })}
                </td>
              </tr>
            </tbody>
          </table>

          <table className={styles.pricing}>
            <tbody>
              <tr>
                <th>{t('order.card.line_item.quantity')}</th>
                <td>{item.quantity}</td>
              </tr>
              <tr>
                <th>{t('order.item.card.price')}</th>
                <td>
                  {t('order.card.line_item.price', {
                    price: item.price,
                    currency: item.currency ?? 'GBP'
                  })}
                </td>
              </tr>
              <tr>
                <th className={styles.bold}>{t('order.item.card.subtotal')}</th>
                <td>
                  {t('order.card.line_item.price', {
                    price: item.quantity * (item.price ?? 0),
                    currency: item.currency ?? 'GBP'
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {item.spend_categories && item.spend_categories.length > 0 && (
          <div className={styles['spend-categories']}>
            <p>{t('order.item.card.spend_categories')}</p>
            {item.spend_categories.map((spendCategory, index) => (
              <div
                className={styles['spend-category']}
                key={`${spendCategory.code}-${index}`}
              >
                {index !== 0 && <p>&gt;</p>}
                <b>{spendCategory.code}</b>
              </div>
            ))}
          </div>
        )}

        <div
          className={classNames(styles['delivery-and-receipt'], {
            [styles.mobile]: isMobile
          })}
        >
          <p>
            <DeliveryInfo item={item} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default OrderLineItem
