import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ProductCard from './ProductCard'
import MaProductCarousel from '../../common/components/MaProductCarousel'
import styles from '../assets/scss/PurchasedProducts.module.scss'
import useRecentlyPurchased from '../hooks/useRecentlyPurchased'
import LoadingSpinner from '../../common/components/LoadingSpinner'

function RecentlyPurchasedProducts ({
  border = 'top'
}: {
  border?: string
}): ReactElement {
  const { t } = useTranslation()
  const { data, isLoading } = useRecentlyPurchased()
  const products = data?.content ?? []

  return (
    <div className={styles[`purchased-products-${border}`]}>
      <div className={styles.data}>
        <h4>{t('product.recently-purchased.title')}</h4>
      </div>

      {isLoading && <LoadingSpinner />}

      {!isLoading && products.length < 1 && (
        <p className="text-center">{t('product.recently-purchased.empty')}</p>
      )}

      {products.length > 0 && (
        <MaProductCarousel>
          {products.map(product => (
            <div key={product.id} className={styles['purchased-product']}>
              <ProductCard
                productSummary={product}
                variant="alternative"
                currentPage={0}
              />
            </div>
          ))}
        </MaProductCarousel>
      )}
    </div>
  )
}

export default RecentlyPurchasedProducts
