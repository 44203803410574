import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Nav, Tab } from 'react-bootstrap'
import classNames from 'classnames'
import type MaTab from '../models/MaTab'
import styles from '../assets/scss/MaVerticalTabs.module.scss'

interface MaVerticalTabsProps extends HTMLAttributes<HTMLDivElement> {
  tabs: MaTab[]
  activeTab: string
  onSelectTab?: (tabKey: string) => void
}

function MaVerticalTabs ({
  tabs,
  activeTab,
  onSelectTab,
  className
}: MaVerticalTabsProps): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles['ma-vertical-tabs'], className)}>
      <Tab.Container
        activeKey={activeTab}
        defaultActiveKey={activeTab}
        onSelect={eventKey => {
          if (onSelectTab) onSelectTab(eventKey as string)
        }}
      >
        <Nav className={styles['tab-list']}>
          {tabs.map(tab => (
            <Nav.Item
              key={tab.key}
              className={classNames({
                active: activeTab === tab.key
              })}
            >
              <Nav.Link eventKey={tab.key}>
                <span
                  className={classNames({ [styles['no-data']]: !tab.hasData })}
                >
                  {t(`tabs.tab.title.${tab.key}`)}
                </span>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Content className={styles['tab-content']}>
          {tabs.map(tab => (
            <Tab.Pane key={tab.key} eventKey={tab.key}>
              {tab.component}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

export default MaVerticalTabs
