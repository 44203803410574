import { type ReactElement, type HTMLAttributes } from 'react'
import { Pagination } from 'react-bootstrap'
import { BsChevronBarLeft, BsChevronBarRight } from 'react-icons/bs'
import styles from '../assets/scss/DynamicPagination.module.scss'

interface DynamicPaginationProps extends HTMLAttributes<HTMLUListElement> {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

function DynamicPagination ({
  currentPage,
  totalPages,
  onPageChange,
  ...params
}: DynamicPaginationProps): ReactElement {
  const prevPage = (
    <Pagination.Prev
      disabled={currentPage === 1}
      onClick={() => {
        if (currentPage > 1) {
          onPageChange(currentPage - 1)
        }
      }}
      data-testid="pagination-prev"
    >
      <BsChevronBarLeft />
    </Pagination.Prev>
  )
  const nextPage = (
    <Pagination.Next
      disabled={currentPage === totalPages}
      onClick={() => {
        if (currentPage < totalPages) {
          onPageChange(currentPage + 1)
        }
      }}
      data-testid="pagination-next"
    >
      <BsChevronBarRight />
    </Pagination.Next>
  )
  const items: ReactElement[] = [
    <Pagination.Item
      key={1}
      onClick={() => {
        onPageChange(1)
      }}
      active={currentPage === 1}
      data-testid="pagination-first"
    >
      1
    </Pagination.Item>
  ]

  if (currentPage === 1 && totalPages === 1) {
    return (
      <Pagination
        className={styles['dynamic-pagination']}
        data-testid="pagination"
        {...params}
      >
        {prevPage}
        {items}
        {nextPage}
      </Pagination>
    )
  }

  if (currentPage > 4) {
    items.push(<Pagination.Ellipsis key="ellipsis-left" />)
  }

  const range = 2
  const leftOffset = currentPage - range
  const rightOffset = currentPage + range

  for (
    let i = leftOffset > 2 ? leftOffset : 2;
    i <= Math.min(totalPages, rightOffset);
    i++
  ) {
    items.push(
      <Pagination.Item
        key={i}
        active={currentPage === i}
        onClick={() => {
          onPageChange(i)
        }}
        data-testid={i === totalPages ? 'pagination-last' : ''}
      >
        {i}
      </Pagination.Item>
    )
  }

  if (rightOffset + 1 < totalPages) {
    items.push(<Pagination.Ellipsis key="ellipsis-right" />)
  }

  if (rightOffset < totalPages) {
    items.push(
      <Pagination.Item
        key={totalPages}
        onClick={() => {
          onPageChange(totalPages)
        }}
        data-testid="pagination-last"
      >
        {totalPages}
      </Pagination.Item>
    )
  }

  return (
    <Pagination
      className={styles['dynamic-pagination']}
      size="sm"
      data-testid="pagination"
      {...params}
    >
      {prevPage}
      {items}
      {nextPage}
    </Pagination>
  )
}

export default DynamicPagination
