import {
  type ChangeEvent,
  useState,
  type ReactElement,
  type FormEvent,
  type ReactNode
} from 'react'
import { useTranslation } from 'react-i18next'
import { type Product } from '@amici/myamici-search-client'
import { Button, Spinner } from 'react-bootstrap'
import { BsCheck, BsExclamationTriangle } from 'react-icons/bs'
import MaModal from '../../common/components/MaModal'
import SearchForm from '../../purchasing/components/SearchForm'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useProductSearch from '../../purchasing/hooks/useProductSearch'
import { ProductSearchType } from '../../purchasing/types/product-search-type'
import useProductDetails from '../../purchasing/hooks/useProductDetails'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import SupplierStatusIndicator from '../../purchasing/components/SupplierStatusIndicator'
import styles from '../assets/scss/OrderRequestAddLineItemModal.module.scss'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'

const MAX_ITEMS_PER_PAGE = 25

function FoundProduct ({
  product,
  orderRequestId
}: {
  product: Product
  orderRequestId: string
}): ReactElement {
  const { t } = useTranslation()
  const { data: productResource, error: productError } = useProductDetails(
    product.id.toString()
  )
  const { lineItems, addLineItem, isAdding, errorAdding } =
    useOrderRequestLineItems(orderRequestId)
  const [isAdded, setIsAdded] = useState(false)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)

  const orderRequestCurrency = lineItems[0]?.line_item.currency
  const currencyMismatch =
    orderRequestCurrency && product.currencyCode !== orderRequestCurrency
  const isSelfServeProduct = product.isSelfServe
  const showWarning = productError || currencyMismatch || isSelfServeProduct
  const canAdd = !isAdded && !showWarning

  const handleAddLineItem = async (): Promise<void> => {
    if (!productResource) {
      return
    }

    try {
      await addLineItem(productResource)
      setIsAdded(true)
    } catch (e) {
      // Don't need to do anything extra here
      // because the error state is provided by SWR
      setIsAdded(false)
    }

    await refreshHistory()
  }

  return (
    <div className={styles.product} role="listitem">
      <div className={styles.description}>
        <h6>
          {product.supplierPartNo} - {product.description}
        </h6>

        <div className={styles.supplier}>
          <span>{product?.supplier}</span>

          <SupplierStatusIndicator productId={product?.id.toString()} />
        </div>
      </div>

      <div className={styles.status}>
        {isAdded && !errorAdding && (
          <BsCheck
            className={styles['success-icon']}
            size={24}
            role="graphics-symbol"
          />
        )}

        {showWarning && (
          <BsExclamationTriangle
            className={styles['warning-icon']}
            size={24}
            role="graphics-symbol"
            title={
              (productError &&
                t(
                  'order_request.add_line_item.warning.product_does_not_exist'
                )) ||
              (currencyMismatch &&
                t('order_request.add_line_item.warning.currency_mismatch')) ||
              (isSelfServeProduct &&
                t('order_request.add_line_item.warning.self_serve_product'))
            }
          />
        )}

        {canAdd && !isAdding && (
          <Button
            className="rounded"
            title={
              errorAdding
                ? t('order_request.add_line_item.error')
                : t('common.button.labels.add')
            }
            variant={errorAdding ? 'outline-danger' : 'outline-primary'}
            disabled={!productResource}
            onClick={() => {
              void handleAddLineItem()
            }}
          >
            {t('common.button.labels.add')}
          </Button>
        )}

        {isAdding && <Spinner variant="primary" role="status" />}
      </div>
    </div>
  )
}

function SearchResultsSummary ({
  hasResults,
  isLoading,
  totalElements,
  searchTerm
}: {
  hasResults: boolean
  isLoading: boolean
  totalElements: number
  searchTerm: string
}): ReactNode {
  const { t } = useTranslation()

  const showResultsPlaceholder = !hasResults && !isLoading
  const showResultsSummary = hasResults && totalElements > 0
  const showNotFoundMessage = hasResults && totalElements === 0
  const showMaxResultsSummary =
    hasResults && totalElements >= MAX_ITEMS_PER_PAGE

  if (isLoading) {
    return (
      <div className={styles.placeholder}>
        <LoadingSpinner />
      </div>
    )
  }

  if (showResultsPlaceholder) {
    return (
      <div className={styles.placeholder}>
        <p>{t('order_request.add_line_item.results.placeholder')}</p>
      </div>
    )
  }

  if (showNotFoundMessage) {
    return (
      <div className={styles['not-found']}>
        <p>
          {t('order_request.add_line_item.results.not_found.part_1', {
            searchTerm
          })}
        </p>
        <p>{t('order_request.add_line_item.results.not_found.part_2')}</p>
      </div>
    )
  }

  if (showResultsSummary && !showMaxResultsSummary) {
    return (
      <p className={styles.summary}>
        {t('order_request.add_line_item.results.found', {
          count: totalElements
        })}
      </p>
    )
  }

  if (showResultsSummary && showMaxResultsSummary) {
    return (
      <p className={styles.summary}>
        {t('order_request.add_line_item.results.found_top_max', {
          count: MAX_ITEMS_PER_PAGE
        })}
      </p>
    )
  }
}

export interface OrderRequestAddLineItemModalProps {
  show: boolean
  orderRequestId: string
  onClose: () => void
}

function OrderRequestAddLineItemModal ({
  show,
  orderRequestId,
  onClose
}: OrderRequestAddLineItemModalProps): ReactElement {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const {
    data: searchData,
    searchParams,
    setSearchParams,
    isLoading
  } = useProductSearch()

  const { results, totalElements = 0 } = searchData || {}

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value)
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    setSearchParams({
      ...searchParams,
      pageSize: MAX_ITEMS_PER_PAGE,
      type: ProductSearchType.PartNumber,
      term: searchTerm.trim()
    })
  }

  const handleClose = (): void => {
    setSearchTerm('')
    setSearchParams({
      ...searchParams,
      term: ''
    })
    onClose()
  }

  return (
    <MaModal
      className={styles.modal}
      title={t('order_request.add_line_item.title')}
      show={show}
      size="lg"
      onClose={handleClose}
      footer={
        <Button variant="outline-danger" onClick={handleClose}>
          {t('common.button.labels.close')}
        </Button>
      }
    >
      <SearchForm
        search={searchTerm}
        onSearchChange={handleSearchChange}
        onSubmit={handleSubmit}
        placeholder={t('order_request.add_line_item.placeholder')}
        aria-label={t('order_request.add_line_item.placeholder')}
      />

      <SearchResultsSummary
        hasResults={!!results}
        isLoading={isLoading}
        totalElements={totalElements}
        searchTerm={searchParams.term}
      />

      <div className={styles.products} role="list">
        {results?.map(product => (
          <FoundProduct
            key={`${searchParams.term}-${product.id}`}
            orderRequestId={orderRequestId}
            product={product}
          />
        ))}
      </div>
    </MaModal>
  )
}

export default OrderRequestAddLineItemModal
