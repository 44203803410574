import { type ReactElement, useEffect, useMemo } from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {
  AppInsightsContext,
  ReactPlugin
} from '@microsoft/applicationinsights-react-js'
import { useEnv } from '../hooks/useEnv'
import ReactGA from 'react-ga4'

function ApplicationInsightsWrapper ({
  children
}: {
  children: JSX.Element
}): ReactElement {
  const {
    REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    REACT_APP_GA4_MEASUREMENT_ID
  } = useEnv()
  const reactPlugin = useMemo(() => new ReactPlugin(), [])

  useEffect(() => {
    if (!REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
      return
    }

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
      }
    })

    appInsights.loadAppInsights()
  }, [REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING, reactPlugin])

  useEffect(() => {
    if (!REACT_APP_GA4_MEASUREMENT_ID) {
      return
    }

    ReactGA.initialize(REACT_APP_GA4_MEASUREMENT_ID as string)
  }, [REACT_APP_GA4_MEASUREMENT_ID])

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  )
}

export default ApplicationInsightsWrapper
