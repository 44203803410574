import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type OrderSortType } from '../types/order-sort-type'
import { MaSelect } from '../../common/components/MaSelect'

interface OrderSortSelectProps {
  onChange: (value: OrderSortType) => void
  value: OrderSortType
  className?: string
  sortOptions: ReactElement
}

function OrderSortSelect ({
  className,
  onChange,
  value,
  sortOptions
}: OrderSortSelectProps): ReactElement {
  const { t } = useTranslation()

  return (
    <MaSelect
      className={className}
      placeholder={t('common.label.sort_by')}
      label={t('common.label.sort_by')}
      value={value}
      onValueChange={onChange}
    >
      {sortOptions}
    </MaSelect>
  )
}

export default OrderSortSelect
