import { type PropsWithChildren, type ReactElement } from 'react'
import useAccounts from '../hooks/useAccounts'
import LoadingScreen from './LoadingScreen'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface ProtectedRouteProps extends PropsWithChildren {
  requiredPermissions?: string[]
  requiredModules?: string[]
}

function ProtectedRoute ({
  requiredPermissions,
  requiredModules,
  children
}: ProtectedRouteProps): ReactElement {
  const { activeAccount } = useAccounts()
  const { t } = useTranslation()

  if (!activeAccount) {
    return <LoadingScreen />
  }

  if (
    requiredPermissions &&
    requiredPermissions.length > 0 &&
    !requiredPermissions.every(p => activeAccount?.permissions.includes(p))
  ) {
    return (
      <LoadingScreen>
        <Alert variant="danger">{t('loadingscreen.error.access_denied')}</Alert>
      </LoadingScreen>
    )
  }

  if (
    requiredModules &&
    requiredModules.length > 0 &&
    !requiredModules.every(p => activeAccount?.modules.includes(p))
  ) {
    return (
      <LoadingScreen>
        <Alert variant="danger">{t('loadingscreen.error.access_denied')}</Alert>
      </LoadingScreen>
    )
  }

  return <>{children}</>
}

export default ProtectedRoute
