import { type HTMLAttributes, type ReactElement, Fragment, useId } from 'react'
import classNames from 'classnames'
import styles from '../assets/scss/MaDescriptionList.module.scss'

export interface DescriptionListItem {
  term: string
  details?: string | ReactElement | null
  highlight?: boolean
}

function MaDescriptionListItem ({
  item
}: {
  item: DescriptionListItem
}): ReactElement {
  const id = useId()
  return (
    <Fragment>
      <dt id={id} className={styles.term}>
        {item.term}
      </dt>
      <dd className={styles.details} aria-labelledby={id}>
        {item.highlight ? <strong>{item.details}</strong> : item.details}
      </dd>
    </Fragment>
  )
}

interface MaDescriptionListProps extends HTMLAttributes<HTMLDListElement> {
  items: DescriptionListItem[]
}

function MaDescriptionList ({
  items,
  className,
  ...props
}: MaDescriptionListProps): ReactElement {
  return (
    <dl
      role="list"
      className={classNames(className, styles['ma-description-list'])}
      {...props}
    >
      {items.map(item => (
        <MaDescriptionListItem key={item.term} item={item} />
      ))}
    </dl>
  )
}

export default MaDescriptionList
