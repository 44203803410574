import {
  type PropsWithChildren,
  type ReactElement,
  type ReactNode
} from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'
import styles from '../assets/scss/MaHoverCard.module.scss'

interface MaHoverCardProps extends PropsWithChildren {
  trigger: ReactNode
}

function MaHoverCard ({ trigger, children }: MaHoverCardProps): ReactElement {
  return (
    <HoverCard.Root closeDelay={0} openDelay={0}>
      <HoverCard.Trigger asChild>{trigger}</HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          className={styles.content}
          sideOffset={0}
          data-testid="ma-hover-card-content"
        >
          {children}
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}

export default MaHoverCard
