import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useProductPrice from '../hooks/useProductPrice'
import styles from '../assets/scss/ProductPrice.module.scss'

interface ProductPriceProps extends HTMLAttributes<HTMLDivElement> {
  productId: string
  currency: string | null
  listPrice: number | null
  bestPrice: number | null
  isSelfServe: boolean
  variant?: 'full' | 'compact' | 'minimal'
}

function ProductPrice ({
  productId,
  currency,
  listPrice,
  bestPrice,
  isSelfServe,
  variant = 'compact'
}: ProductPriceProps): ReactElement {
  const { t } = useTranslation()

  const productPriceData = useProductPrice(
    productId,
    currency,
    listPrice,
    bestPrice
  )
  const discount =
    productPriceData.bestPrice != null && productPriceData.listPrice != null
      ? (productPriceData.bestPrice / productPriceData.listPrice - 1) * 100
      : 0

  if (productPriceData.currency == null) {
    return (
      <div className={classNames(styles['product-price'], styles[variant])}>
        <div className={styles.price}>&nbsp;</div>
        {variant !== 'minimal' && <div>&nbsp;</div>}
      </div>
    )
  }

  if (isSelfServe) {
    return (
      <div className={classNames(styles['product-price'], styles[variant])}>
        <div className={styles.price}>{t('product.card.self_serve')}</div>
        {variant !== 'minimal' && <div>&nbsp;</div>}
      </div>
    )
  }

  if (productPriceData.bestPrice == null || productPriceData.bestPrice <= 0) {
    return (
      <div className={classNames(styles['product-price'], styles[variant])}>
        <div className={styles.price}>{t('product.card.price_on_request')}</div>
        {variant !== 'minimal' && <div>&nbsp;</div>}
      </div>
    )
  }

  if (
    productPriceData.listPrice == null ||
    productPriceData.listPrice <= productPriceData.bestPrice ||
    discount === 0
  ) {
    return (
      <div className={classNames(styles['product-price'], styles[variant])}>
        <div className={styles.price}>
          {t('product.card.price', {
            price: productPriceData.bestPrice,
            currency: productPriceData.currency
          })}
        </div>
        {variant !== 'minimal' && <div>&nbsp;</div>}
      </div>
    )
  }

  return (
    <div className={classNames(styles['product-price'], styles[variant])}>
      <div className={styles.price}>
        {t('product.card.price', {
          price: productPriceData.bestPrice,
          currency: productPriceData.currency
        })}
      </div>
      {variant !== 'minimal' && (
        <div className={styles['discount-info']}>
          <span className={styles['list-price']}>
            {variant === 'full' && (
              <span className={styles.label}>
                {t('product.details.price_label.list_price')}{' '}
              </span>
            )}
            <s>
              {t('product.card.price', {
                price: productPriceData.listPrice,
                currency: productPriceData.currency
              })}
            </s>
          </span>
          <span className={styles['price-discount']}>
            {variant === 'full' && (
              <span className={styles.label}>
                {t('product.details.price_label.discount')}{' '}
              </span>
            )}
            {t('product.card.discount', {
              discount: variant === 'full' ? -discount : discount
            })}
          </span>
        </div>
      )}
    </div>
  )
}

export default ProductPrice
