import { type HTMLAttributes, type ReactElement, useState } from 'react'
import { type OrderLineItemResource } from '@amici/myamici-api-client'
import styles from '../assets/scss/OrderLineItems.module.scss'
import OrderLineItem from './OrderLineItem'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface OrderLineItemsProps extends HTMLAttributes<HTMLDivElement> {
  items: OrderLineItemResource[] | undefined
  variant?: 'full' | 'summary'
  max: number
  showAllOption?: boolean
}

function OrderLineItems ({
  items,
  variant = 'full',
  max = 0,
  showAllOption = false
}: OrderLineItemsProps): ReactElement {
  const { t } = useTranslation()

  const [showingAll, setShowingAll] = useState<boolean>(false)
  const handleToggleShowAll = (): void => {
    setShowingAll(!showingAll)
  }

  return (
    <div
      className={classNames(styles['line-items'], {
        [styles.summary]: variant === 'summary'
      })}
    >
      {(!items || items.length === 0) && <p>{t('order.items.none')}</p>}
      {items
        ?.slice(0, showingAll ? items?.length : max)
        .map((lineItem, index) => (
          <div key={lineItem.id}>
            {variant === 'full' && index > 0 && <hr />}
            <OrderLineItem item={lineItem} variant={variant} />
          </div>
        ))}
      {showAllOption && (items?.length ?? 0) > max && (
        <div className={styles['show-all']} onClick={handleToggleShowAll}>
          {showingAll ? t('order.items.show_fewer') : t('order.items.show_all')}
        </div>
      )}
    </div>
  )
}

export default OrderLineItems
