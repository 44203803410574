import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import DynamicPagination from '../../common/components/DynamicPagination'
import styles from '../assets/scss/OrderItemsPagination.module.scss'

interface OrderItemsPaginationProps extends HTMLAttributes<HTMLElement> {
  totalPages: number
  currentPage: number
  pageSize: number
  pageStartRow: number
  pageEndRow: number
  totalRowsCount: number
  standalone: boolean
  onPageChange: (value: number) => void
  onPageSizeChange: (value: number) => void
}

function OrderItemsPagination ({
  totalPages,
  currentPage,
  pageSize,
  pageStartRow,
  pageEndRow,
  totalRowsCount,
  standalone,
  onPageChange,
  onPageSizeChange
}: OrderItemsPaginationProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Row
      className={classNames(styles['order-items-pagination'], {
        [styles.standalone]: standalone
      })}
    >
      <Col xs="6" sm="4" lg={{ offset: 3, span: 2 }}>
        <Form.Group className={styles['page-size']}>
          <Form.Label>
            {t('outstanding_order_items.table.items_per_page')}
          </Form.Label>

          <MaSelect
            value={pageSize.toString()}
            onValueChange={value => {
              onPageSizeChange(parseInt(value))
            }}
            data-testid="page-size-select"
          >
            <MaSelectItem value="24">24</MaSelectItem>
            <MaSelectItem value="48">48</MaSelectItem>
          </MaSelect>
        </Form.Group>
      </Col>
      <Col
        xs="6"
        sm="4"
        lg="2"
        className="d-flex justify-content-center align-items-center"
      >
        <DynamicPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </Col>
      <Col
        xs="12"
        sm="4"
        lg="2"
        className="d-sm-flex d-none justify-content-end align-items-center"
      >
        <p
          className={classNames(styles.total, 'float-md-end')}
          data-testid="total-rows"
        >
          {t('outstanding_order_items.table.rows', {
            start: pageStartRow,
            end: pageEndRow,
            total: totalRowsCount
          })}
        </p>
      </Col>
    </Row>
  )
}

export default OrderItemsPagination
