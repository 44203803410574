import { type ReactElement } from 'react'
import { Container } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useEnv } from '../../common/hooks/useEnv'
import usePurchaseOrder from '../hooks/usePurchaseOrder'
import useOrderItems from '../hooks/useOrderItems'
import PurchaseOrderHeader from '../components/PurchaseOrderHeader'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import PurchaseOrderDetails from '../components/PurchaseOrderDetails'
import PurchaseOrderSummaryOfCharges from '../components/PurchaseOrderSummaryOfCharges'
import PurchaseOrderDeliveryInstructions from '../components/PurchaseOrderDeliveryInstructions'
import PurchaseOrderOrderItems from '../components/PurchaseOrderOrderItems'
import styles from '../assets/scss/PurchaseOrder.module.scss'
import OrderHistory from '../components/OrderHistory'

function PurchaseOrder (): ReactElement {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { t } = useTranslation()
  const { orderId } = useParams()

  const { isLoading, error, data: order } = usePurchaseOrder(orderId)
  const { data: lineItems } = useOrderItems(orderId ?? '')

  const showOrder = !isLoading && !error && order
  const showError = !isLoading && error

  const ma3OrderUrl = order
    ? `${REACT_APP_MA3_BASE_URL}/Order/OrderView.aspx?OrderId=${order?.id}`
    : ''

  return (
    <Container fluid="auto" className={classNames('ma-page')}>
      <PurchaseOrderHeader />

      {isLoading && !error && <LoadingSpinner />}

      {showError && (
        <p className={styles.error}>
          {t('purchase_order.not_found.sorry')}{' '}
          {t('purchase_order.not_found.message', { order_id: orderId })}
        </p>
      )}

      {showOrder && (
        <>
          <h2 className={styles['order-title']}>
            <Link to={ma3OrderUrl} target="_blank">
              {order?.reference}
            </Link>{' '}
            {order?.confidential && (
              <span className={styles.confidential}>
                {t('purchase_order.title.confidential')}
              </span>
            )}
          </h2>

          <div className={styles['panels-grid']}>
            <div className={styles['panels-row']}>
              <PurchaseOrderDetails
                className={styles['order-details']}
                order={order}
              />

              <PurchaseOrderSummaryOfCharges
                className={styles['summary-of-charges']}
                order={order}
              />
            </div>

            <PurchaseOrderDeliveryInstructions order={order} />

            <PurchaseOrderOrderItems lineItems={lineItems?.content} />

            <OrderHistory order={order} lineItems={lineItems} />

            {/* TODO: extract into its own component */}
            {/* <MaPanel className={styles['additional-details']}>
              <MaPanel.Body />
            </MaPanel> */}
          </div>
        </>
      )}
    </Container>
  )
}

export default PurchaseOrder
