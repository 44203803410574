import {
  type OrderRequestHistoryEntry,
  OrderRequestHistoryEntryAllOfEventTypeEnum
} from '@amici/myamici-api-client'
import type { ReactNode } from 'react'
import styles from '../assets/scss/OrderRequest.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
import { formatDelimitedAddress } from '../../common/utils/format-address'
import MaDescriptionList from '../../common/components/MaDescriptionList'
import type { TFunction } from 'i18next'

function editFieldEntryType (
  oldValuePresent: boolean,
  newValuePresent: boolean
): 'removed' | 'added' | 'updated' {
  if (oldValuePresent && !newValuePresent) {
    return 'removed'
  } else if (!oldValuePresent && newValuePresent) {
    return 'added'
  }
  return 'updated'
}

function formatDate (date: string): string {
  return format(parseISO(date), 'd MMM yyyy')
}

function formatTime (date: string): string {
  return format(parseISO(date), 'HH:mm')
}

function confidentialStatus (
  confidential: string,
  t: TFunction<any, any>
): string {
  return confidential === 'true'
    ? t('order_request.history.confidential')
    : t('order_request.history.non-confidential')
}

function OrderRequestHistoryEvent ({
  i18nKey,
  values
}: {
  i18nKey: string
  values: Record<string, string | undefined>
}): ReactNode {
  return (
    <div className={styles['history-event']}>
      <Trans i18nKey={i18nKey} values={values} />
    </div>
  )
}

function OrderRequestHistoryCreateEntry ({
  entry
}: {
  entry: OrderRequestHistoryEntry
}): ReactNode {
  return (
    <OrderRequestHistoryEvent
      i18nKey={'order_request.history.create_order_request'}
      values={{
        username: entry.username,
        date: formatDate(entry.created),
        time: formatTime(entry.created),
        full_name: entry.new_values?.full_name
      }}
    />
  )
}

function OrderRequestHistoryEditFieldEntry ({
  entry,
  field,
  newValue,
  oldValue
}: {
  entry: OrderRequestHistoryEntry
  field: string
  newValue: string
  oldValue: string
}): ReactNode {
  const editType = editFieldEntryType(!!oldValue, !!newValue)
  const { t } = useTranslation()

  if (field === 'date_required') {
    newValue = newValue ? formatDate(newValue) : newValue
    oldValue = oldValue ? formatDate(oldValue) : oldValue
  } else if (field === 'confidential') {
    newValue = confidentialStatus(newValue, t)
    oldValue = confidentialStatus(oldValue, t)
  }

  const editTypeValues = {
    updated: {
      newValue,
      oldValue
    },
    added: {
      newValue
    },
    removed: {
      oldValue
    }
  }[editType]

  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request.${field}`}
          values={{
            username: entry.username,
            date: formatDate(entry.created),
            time: formatTime(entry.created)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request.${editType}`}
          values={editTypeValues}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryAddLineItemEntry ({
  entry
}: {
  entry: OrderRequestHistoryEntry
}): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={'order_request.history.add_order_request_line_item'}
          values={{
            username: entry.username,
            date: formatDate(entry.created),
            time: formatTime(entry.created)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={'order_request.history.add_order_request_line_item.added'}
          values={{
            product_spn: entry.new_values?.product_spn,
            product_name: entry.new_values?.product_name
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryRemoveLineItemEntry ({
  entry
}: {
  entry: OrderRequestHistoryEntry
}): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={'order_request.history.remove_order_request_line_item'}
          values={{
            username: entry.username,
            date: formatDate(entry.created),
            time: formatTime(entry.created)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={
            'order_request.history.remove_order_request_line_item.removed'
          }
          values={{
            product_spn: entry.old_values?.product_spn,
            product_name: entry.old_values?.product_name
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryCancelEntry ({
  entry
}: {
  entry: OrderRequestHistoryEntry
}): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={'order_request.history.cancel_order_request'}
          values={{
            username: entry.username,
            date: formatDate(entry.created),
            time: formatTime(entry.created)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={'order_request.history.cancel_order_request.reason'}
          values={{
            cancellation_reason: entry.new_values?.cancellation_reason
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryDeliveryDetailsEntry ({
  entry,
  field,
  newValue,
  oldValue
}: {
  entry: OrderRequestHistoryEntry
  field: string
  newValue: string
  oldValue: string
}): ReactNode {
  const { t } = useTranslation()

  if (field === 'delivery_address_id') {
    return
  }

  let historyEntry: ReactNode

  if (field === 'delivery_address') {
    const oldAddress = formatDelimitedAddress(
      entry.old_values?.delivery_address
    )
    const newAddress = formatDelimitedAddress(
      entry.new_values?.delivery_address
    )

    const oldAddressItems = entry.old_values?.delivery_address
      ? [
          {
            key: '1',
            term: t('common.label.from'),
            details: oldAddress
          }
        ]
      : []
    const newAddressItems = entry.new_values?.delivery_address
      ? [
          {
            key: '2',
            term: t('common.label.to'),
            details: newAddress
          }
        ]
      : []

    historyEntry = (
      <>
        <Trans
          i18nKey={
            'order_request.history.edit_order_request_delivery_details.delivery_address'
          }
          values={{
            username: entry.username,
            date: formatDate(entry.created),
            time: formatTime(entry.created)
          }}
        />
        <div className={styles['delivery-info']}>
          <div className={styles['address-history']}>
            <MaDescriptionList items={oldAddressItems} />
            <MaDescriptionList items={newAddressItems} />
          </div>
        </div>
      </>
    )
  } else {
    const editType = editFieldEntryType(!!oldValue, !!newValue)

    const editTypeValues = {
      updated: {
        newValue,
        oldValue
      },
      added: {
        newValue
      },
      removed: {
        oldValue
      }
    }[editType]

    historyEntry = (
      <>
        <Trans
          i18nKey={`order_request.history.edit_order_request_delivery_details.${field}`}
          values={{
            username: entry.username,
            date: formatDate(entry.created),
            time: formatTime(entry.created)
          }}
        />
        <div>
          <Trans
            i18nKey={`order_request.history.edit_order_request.${editType}`}
            values={editTypeValues}
          />
        </div>
      </>
    )
  }

  return <div className={styles['history-event']}>{historyEntry}</div>
}

function OrderRequestHistoryEditLineItemQuantity ({
  username,
  created,
  productName,
  newQuantity,
  oldQuantity
}: {
  username: string
  created: string
  productName: string
  newQuantity: string
  oldQuantity: string
}): ReactNode {
  return (
    <OrderRequestHistoryEvent
      i18nKey={'order_request.history.edit_order_request_line_item.quantity'}
      values={{
        username,
        date: format(new Date(created), 'd MMM yyyy'),
        time: format(new Date(created), 'HH:mm'),
        productName,
        oldQuantity,
        newQuantity
      }}
    />
  )
}

function OrderRequestHistoryEditLineItemFieldEntry ({
  entry,
  field,
  productName,
  fieldName,
  fromPath
}: {
  entry: OrderRequestHistoryEntry
  field: string
  productName: string
  fieldName: string
  fromPath: string
}): ReactNode {
  let editLineItemFieldEntry = null
  if (entry.new_values && entry.old_values) {
    if (field === 'quantity') {
      editLineItemFieldEntry = (
        <OrderRequestHistoryEditLineItemQuantity
          username={entry.username}
          created={entry.created}
          productName={productName}
          newQuantity={entry.new_values.quantity}
          oldQuantity={entry.old_values.quantity}
        />
      )
    } else if (field === 'spend_category_path') {
      editLineItemFieldEntry =
        fromPath.length > 0
          ? (
          <OrderRequestHistoryEvent
            i18nKey={
              'order_request.history.edit_order_request_line_item.spend_category_path.updated'
            }
            values={{
              username: entry.username,
              date: formatDate(entry.created),
              time: formatTime(entry.created),
              productName,
              fieldName,
              oldSpendCategoryPath: entry.old_values.spend_category_path,
              newSpendCategoryPath: entry.new_values.spend_category_path
            }}
          />
            )
          : (
          <OrderRequestHistoryEvent
            i18nKey={
              'order_request.history.edit_order_request_line_item.spend_category_path.added'
            }
            values={{
              username: entry.username,
              date: formatDate(entry.created),
              time: formatTime(entry.created),
              productName,
              fieldName,
              newSpendCategoryPath: entry.new_values.spend_category_path
            }}
          />
            )
    }
  }
  return editLineItemFieldEntry
}

function OrderRequestHistoryEventEntry ({
  entry
}: {
  entry: OrderRequestHistoryEntry
}): ReactNode {
  let eventEntry = null
  switch (entry.event_type) {
    case OrderRequestHistoryEntryAllOfEventTypeEnum.CREATE_ORDER_REQUEST:
      eventEntry = <OrderRequestHistoryCreateEntry entry={entry} />
      break
    case OrderRequestHistoryEntryAllOfEventTypeEnum.EDIT_ORDER_REQUEST:
      eventEntry = (
        <>
          {Object.entries(entry.new_values ?? {}).map(
            ([field, newValue]) =>
              entry.old_values &&
              entry.old_values[field] !== newValue && (
                <OrderRequestHistoryEditFieldEntry
                  key={field}
                  entry={entry}
                  field={field}
                  newValue={newValue}
                  oldValue={entry.old_values[field]}
                />
              )
          )}
        </>
      )
      break
    case OrderRequestHistoryEntryAllOfEventTypeEnum.ADD_ORDER_REQUEST_LINE_ITEM:
      eventEntry = <OrderRequestHistoryAddLineItemEntry entry={entry} />
      break
    case OrderRequestHistoryEntryAllOfEventTypeEnum.EDIT_ORDER_REQUEST_LINE_ITEM:
      eventEntry = (
        <>
          {Object.entries(entry.new_values ?? {}).map(
            ([field, newValue]) =>
              entry.old_values &&
              entry.old_values[field] !== newValue && (
                <OrderRequestHistoryEditLineItemFieldEntry
                  key={field}
                  entry={entry}
                  field={field}
                  productName={entry.old_values.product_name}
                  fieldName={entry.old_values.spend_category_field_name}
                  fromPath={entry.old_values.spend_category_path}
                />
              )
          )}
        </>
      )
      break
    case OrderRequestHistoryEntryAllOfEventTypeEnum.REMOVE_ORDER_REQUEST_LINE_ITEM:
      eventEntry = <OrderRequestHistoryRemoveLineItemEntry entry={entry} />
      break
    case OrderRequestHistoryEntryAllOfEventTypeEnum.CANCEL_ORDER_REQUEST:
      eventEntry = <OrderRequestHistoryCancelEntry entry={entry} />
      break
    case OrderRequestHistoryEntryAllOfEventTypeEnum.EDIT_ORDER_REQUEST_DELIVERY_DETAILS:
      eventEntry = (
        <>
          {Object.entries(entry.new_values ?? {}).map(
            ([field, newValue]) =>
              entry.old_values &&
              entry.old_values[field] !== newValue && (
                <OrderRequestHistoryDeliveryDetailsEntry
                  key={field}
                  entry={entry}
                  field={field}
                  newValue={newValue}
                  oldValue={entry.old_values[field]}
                />
              )
          )}
        </>
      )
      break
  }

  return eventEntry
}

export default OrderRequestHistoryEventEntry
