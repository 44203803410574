import { type ReactElement, type HTMLAttributes } from 'react'
import { type OrderResource } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import MaPanel from '../../common/components/MaPanel'
import MaDescriptionList, {
  type DescriptionListItem
} from '../../common/components/MaDescriptionList'
import useOrderItems from '../hooks/useOrderItems'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'

function PurchaseOrderSummaryOfCharges ({
  order,
  ...props
}: HTMLAttributes<HTMLElement> & { order: OrderResource }): ReactElement {
  const { t } = useTranslation()
  const { isLoading, error, data: orderItems } = useOrderItems(order?.id)

  const subtotal = orderItems?.content?.reduce(
    (subtotal, item) => subtotal + item.quantity * (item.price ?? 0),
    0
  )
  const total =
    (subtotal ?? 0) + (order?.order_charge ?? 0) + (order?.carriage_charge ?? 0)

  const hidePrices = isLoading || !!error || !order

  const descriptionItems: DescriptionListItem[] = [
    {
      term: t('purchase_order.summary_of_charges.subtotal'),
      details: (
        <div className="text-end">
          {hidePrices
            ? '-'
            : t('purchase_order.summary_of_charges.price', {
              price: subtotal,
              currency: order?.currency ?? 'GBP'
            })}
        </div>
      )
    },
    {
      term: t('purchase_order.summary_of_charges.min_order_charge'),
      details: (
        <div className="text-end">
          {hidePrices
            ? '-'
            : t('purchase_order.summary_of_charges.price', {
              price: order?.order_charge,
              currency: order?.currency ?? 'GBP'
            })}
        </div>
      )
    },
    {
      term: t('purchase_order.summary_of_charges.shipping'),
      details: (
        <div className="text-end">
          {hidePrices
            ? '-'
            : t('purchase_order.summary_of_charges.price', {
              price: order?.carriage_charge,
              currency: order?.currency ?? 'GBP'
            })}
        </div>
      )
    }
  ]

  return (
    <MaPanel className={props.className}>
      <MaPanel.Header>
        <h5 className={styles.title}>
          {t('purchase_order.summary_of_charges.title')}
        </h5>
      </MaPanel.Header>

      <MaPanel.Body>
        <MaDescriptionList items={descriptionItems} />
      </MaPanel.Body>

      <MaPanel.Footer>
        <div className="d-flex flex-direction-row justify-content-between">
          <span>{t('purchase_order.summary_of_charges.total')}</span>
          <span>
            {hidePrices
              ? '-'
              : t('purchase_order.summary_of_charges.price', {
                price: total,
                currency: order?.currency ?? 'GBP'
              })}
          </span>
        </div>
      </MaPanel.Footer>
    </MaPanel>
  )
}

export default PurchaseOrderSummaryOfCharges
