import { type ReactElement, useMemo, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { Trans, useTranslation } from 'react-i18next'
import {
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum
} from '@amici/myamici-api-client'
import { formatAddress } from '../../common/utils/format-address'
import useAccounts from '../../common/hooks/useAccounts'
import MaDescriptionList from '../../common/components/MaDescriptionList'
import MaActionIcon from '../../common/components/MaActionIcon'
import styles from '../assets/scss/OrderRequest.module.scss'
import { UserPermission } from '../../common/types/user-permission'
import OrderRequestEditDeliveryInfoModal, {
  type DeliveryInfo
} from './OrderRequestEditDeliveryInfoModal'
import useOrderRequest from '../hooks/useOrderRequest'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaToast from '../../common/components/MaToast'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'

function OrderRequestDeliveryInfo ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement {
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { t } = useTranslation()
  const { accountProfile, activeAccount } = useAccounts()
  const { update, isUpdating } = useOrderRequest(orderRequest.id)
  const { lineItems } = useOrderRequestLineItems(orderRequest.id)
  const { suppliers } = useOrderRequestSummary(lineItems)
  const { showToast, closeToast } = useToastNotification()
  const [showEditDialog, setShowEditDialog] = useState(false)

  const address = accountProfile?.client?.addresses?.find(
    address => address.id === orderRequest.address_id.toString()
  )
  const noteToSupplier = orderRequest.note_to_supplier

  const infoEntries = useMemo(
    () => [
      ...(address
        ? [
            {
              term: t('order_request.delivery_info.deliver_to'),
              details: formatAddress({
                ...address,
                fao: orderRequest.fao
                  ? t('order_request.delivery_info.fao', {
                    value: orderRequest.fao
                  })
                  : undefined
              })
            }
          ]
        : []),
      ...(noteToSupplier
        ? [
            {
              term: t('order_request.delivery_info.note_to_supplier'),
              details: orderRequest.note_to_supplier
            }
          ]
        : []),
      ...(noteToSupplier && suppliers.length > 1
        ? [
            {
              term: '',
              details: (
                <span className={styles.warning}>
                  <Trans i18nKey="order_request.delivery_info.warning_message" />
                </span>
              )
            }
          ]
        : [])
    ],
    [
      address,
      noteToSupplier,
      orderRequest.note_to_supplier,
      orderRequest.fao,
      suppliers,
      t
    ]
  )

  const handleUpdateError = (): void => {
    const toastId = Date.now()
    showToast(
      toastId,
      <MaToast
        type="danger"
        onClose={() => {
          closeToast(toastId)
        }}
      >
        <span>
          <Trans i18nKey="order_request.delivery_info.edit.update.failure" />
        </span>
      </MaToast>
    )
  }

  const handleConfirm = async (deliveryInfo: DeliveryInfo): Promise<void> => {
    const deliveryInfoAddressId = parseInt(deliveryInfo.addressId, 10)
    if (
      orderRequest.address_id !== deliveryInfoAddressId ||
      orderRequest.fao !== deliveryInfo.fao ||
      orderRequest.note_to_supplier !== deliveryInfo.note
    ) {
      await update({
        ...orderRequest,
        address_id: deliveryInfoAddressId,
        fao: deliveryInfo.fao,
        note_to_supplier: deliveryInfo.note
      })
        .catch(handleUpdateError)
        .finally(() => {
          setShowEditDialog(false)
        })
      await refreshHistory()
    } else {
      setShowEditDialog(false)
    }
  }

  const isFinanceUser = activeAccount?.permissions.some(permission =>
    [UserPermission.IsFinance, UserPermission.IsFinanceUser].includes(
      permission as UserPermission
    )
  )
  const canEdit =
    orderRequest.order_status === OrderRequestAllOfOrderStatusEnum.NEW &&
    (activeAccount?.accountId === orderRequest.requested_by?.id ||
      isFinanceUser)

  return (
    <div className={styles['delivery-info']}>
      <MaDescriptionList className={styles.list} items={infoEntries} />
      {canEdit && (
        <div className={styles.actions}>
          <MaActionIcon
            title={t('order_request.delivery_info.edit')}
            className={styles['action-btn']}
            onClick={() => {
              setShowEditDialog(true)
            }}
          >
            <BsPencil size={16} />
          </MaActionIcon>
        </div>
      )}
      <OrderRequestEditDeliveryInfoModal
        show={showEditDialog}
        onClose={() => {
          setShowEditDialog(false)
        }}
        onConfirm={handleConfirm}
        deliveryInfo={{
          addressId: orderRequest.address_id.toString(),
          fao: orderRequest.fao,
          note: orderRequest.note_to_supplier
        }}
        disabled={isUpdating}
      />
    </div>
  )
}

export default OrderRequestDeliveryInfo
